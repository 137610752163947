import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { PrimeReactProvider } from "primereact/api";
import { ThemeProvider, createTheme } from '@mui/material';
import { OurRoutes } from "./routes";
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import { ToastContainer } from 'react-toastify';

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: [
        'Poppins', 
        'sans-serif',
      ].join(','),
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
    },
  });

  // Resize logic (optional)
  useEffect(() => {
    const resizeWindow = () => {
      const screenWidth = window.screen.availWidth;
      const newWidth = Math.floor(screenWidth * 0.67); // Set width to 67% of screen width
      window.resizeTo(newWidth, window.innerHeight);
    };

    resizeWindow(); // Resize window when the component mounts
  }, []); // Removed unnecessary cleanup

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <PrimeReactProvider ripple={true}>
          <OurRoutes />
          <ToastContainer autoClose={2000} />
        </PrimeReactProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
