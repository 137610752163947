import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import loginDesign from "../assets/loginDesign.svg";
import login1 from "../assets/login1.png";
import login2 from "../assets/loginlogoo.svg";
import loginLogo from "../assets/loginLogo.svg";
import { encryptData } from "./encryption";
import { toast } from "react-toastify";
import { APP_ROUTES } from "../constants/approutes";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({ userCode: "", passWord: "" });
  const [userinfo, setUserinfo] = useState(null);
  const navigate = useNavigate();

  const API_URL = process.env.REACT_APP_API_URL;

  const getProfile = async (userCode) => {
    try {
      if (userCode) {
        const response = await fetch(`${API_URL}user/getProfile`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code: userCode }),
        });
        const data = await response.json();
        console.log(userCode, data, "responsedataaaaa");
      } else {
        console.log("No user code found");
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = (email) => {
    setUser({ ...user, userCode: email });
  };

  const handlePassword = (input) => {
    let encoded = btoa(input); // Using btoa to encode the password
    setPassword(input);
    setUser({ ...user, passWord: encoded });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignIn = async (event) => {
    event.preventDefault();

    if (!validateEmail(user.userCode)) {
      toast.error("Please enter a valid email address");
      return;
    }

    if (user.passWord === "") {
      toast.error("Password cannot be empty");
      return;
    }

    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let encryptedPayload = encryptData(user);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(encryptedPayload),
        redirect: "follow",
      };

      const response = await fetch(`${API_URL}login/withAD`, requestOptions);

      const result = await response.json();

      console.log(result, "resulttttttttt");

      if (result?.successObject?.user) {
        const userRole = result.successObject.user.rolecode;

        if (userRole) {
          setUserinfo(result.successObject.user);
          localStorage.setItem("userCode", result.successObject.user.code);
          localStorage.setItem(
            "userInfo",
            JSON.stringify(result.successObject.user)
          );
          localStorage.setItem(
            "encryptedPayload",
            JSON.stringify(encryptedPayload)
          );
          await getProfile(result.successObject.user.code);
          localStorage.setItem("roleCode", result.successObject.user.rolecode);
          localStorage.setItem("isLogged", "true");
          toast.success("Login successful");
          localStorage.setItem(
            "retailerName",
            result.successObject.user.partnerName
          );

          const partnerCode = result.successObject.user.partnerCode;
          const retailerName = result.successObject.user.partnerName;

          if (userRole === "RET") {
            navigate(APP_ROUTES.DASHBOARD, {
              state: { partnerCode, retailerName },
            });
          } else if (
            userRole === "FA" ||
            userRole === "SE" ||
            userRole === "SBH"
          ) {
            navigate("/Orderdetails");
          } else {
            toast.error("Login failed Unauthorized User");
          }
        } else {
          toast.error("Please check your credentials and try again.");
        }
      } else {
        toast.error(result.responseMessage || "Login failed");
        setUser({ ...user, userCode: "", passWord: "" });
        setPassword("");
      }
    } catch (error) {
      setUser({ ...user, userCode: "", passWord: "" });
      setPassword("");
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="flex  text-[#004172] overflow-hidden h-screen">
      <div className="flex-1 hidden lg:flex items-center justify-center">
        <div
          className="h-full w-full relative"
          style={{
            background:
              "linear-gradient(180deg, #004172 0%, #004A7B 38.01%, #00B2DD 100%)",
          }}
        >
          <img src={login1} alt="Login" className="w-full object-cover" />
          <div className="absolute top-0 flex flex-col justify-between items-center font-bold text-center text-white left-0 w-full h-full">
            <div className="mt-12">
              <div className="text-4xl">Welcome To CureBay Connect</div>
              <p className="text-lg mt-3 font-semibold">
                Manage your services from here!
              </p>
            </div>
            <div className="flex-1 flex items-center justify-center mt-[116px]">
              <img
                src={login2}
                alt="Login"
                className="bottom-0 w-[96%] max-w-md h-auto"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 flex flex-col items-center justify-center bg-white relative">
        <img
          src={loginLogo}
          alt="Login Design"
          className="absolute top-3 left-3 w-[30%]"
        />

        <div className="mb-0 text-center mt-14">
          <p className="text-3xl font-medium">Login</p>
          <p className="font-rubik font-medium mt-5 text-gray-800">
            Please enter your valid credentials for logging in
          </p>
          <img src={loginDesign} alt="Login Design" className="mt-4 mx-auto" />
        </div>

        <div className="w-3/4 p-8 bg-white rounded">
          <form className="flex flex-col items-center" onSubmit={handleSignIn}>
            {/* Error Message */}
            {/* Email Input */}
            <div className="mb-2 w-full text-left">
              <label
                htmlFor="email"
                className="text-sm font-semibold text-gray-800"
              >
                Enter Your Email Id
              </label>
            </div>
            <div className="mb-4 w-full">
              <input
                type="email"
                id="email"
                value={user.userCode}
                onChange={(e) => handleEmailChange(e.target.value)}
                placeholder="Enter Email"
                className="block px-4 w-full h-10 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                required
              />
            </div>

            {/* Password Input */}
            <div className="mb-2 w-full text-left">
              <label
                htmlFor="password"
                className="text-sm font-semibold text-gray-800"
              >
                Enter Password
              </label>
            </div>
            <div className="mb-4 w-full relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => handlePassword(e.target.value)}
                placeholder="Enter your password"
                className="block w-full px-4 py-2 mt-2 h-10 text-blue-500 bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring
                                focus:ring-opacity-40 pr-10"
                required
              />
              <span
                className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            <div className="mb-4 w-full mt-5">
              <button
                type="submit"
                className="w-full bg-primary h-10 hover:bg-blue-600 text-white text-lg font-medium py-2 rounded-3xl transition duration-300 ease-in-out"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
