import React, { useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import Drug from "../../assets/drug.svg";
import { toast } from "react-toastify";
import OurLayouts from "../layouts";

Modal.setAppElement("#root");

const OrderTracking = () => {
  const location = useLocation();
  const { order, activeTab } = location.state || {};

  console.log(order, "ewsrdtfyuhijk");
  const API_URL = process.env.REACT_APP_API_URL;
  const API_COMMONURL = process.env.REACT_APP_API_COMMON_URL;
  const [orderItems, setOrderItems] = useState([]);
  const [statusData, setStatusData] = useState({});
  const [error, setError] = useState(null);
  const [isStatusCollapsed, setIsStatusCollapsed] = useState({});
  const [userInfo, setUserInfo] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [formData, setFormData] = useState({
    consignmentId: "",
    statusName: "",
    status: "",
    userName: "",
    userId: "",
    userMobile: "",
    createdBy: "",
    modifiedBy: "",
  });
  const [loading, setLoading] = useState(false);

  console.log(orderItems, "orderItems");

  useEffect(() => {
    const storedUserInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (storedUserInfo) {
      setUserInfo(storedUserInfo);
    }
  }, []);

  // Fetch Order Items
  const fetchOrderItems = async () => {
    try {
      const payload = { orderId: order?.orderId };
      const response = await fetch(`${API_URL}order/getTrackingDetails`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.successList && data.successList.length > 0) {
        setOrderItems(data.successList);
      } else {
        setError("Failed to fetch order item details.");
      }
    } catch (err) {
      setError("An error occurred while fetching order item details.");
    }
  };

  const getOnboardingStatus = async () => {
    try {
      const response = await fetch(`${API_URL}dropdown/getTrackingStatus/`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      if (data.responseCode === "200" && data.successList) {
        const statusMap = data.successList.reduce((acc, item) => {
          acc[item.key] = item.description;
          return acc;
        }, {});
        setStatusData(statusMap);
      } else {
        console.error("Failed to fetch order statuses");
      }
    } catch (error) {
      console.error("Error fetching order statuses:", error);
    }
  };

  useEffect(() => {
    fetchOrderItems();
    getOnboardingStatus();
  }, []);

  const getStatusText = (status) =>
    status ? statusData[status] || "Unknown Status" : "";

  const toggleStatusCollapse = (index) => {
    setIsStatusCollapsed((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const renderProgressBar = (trackingStatus) => {
    const steps = trackingStatus || [];
    return (
      <div className="flex  items-center w-full mt-6">
        {steps.map((step, index) => (
          <div key={index} className="flex items-center flex-1">
            <div className="flex flex-col items-center">
              <p className="text-sm text-[#63b888] font-bold mb-2">
                {new Date(step.createdDate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
                &nbsp;({" "}
                {new Date(step.createdDate).toLocaleTimeString("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                )
              </p>

              <div className="w-8 h-8 rounded-full bg-[#63b888] flex items-center justify-center">
                <span className="text-white font-bold">✓</span>
              </div>
              <p className="text-sm text-gray-800 mt-2">
                {getStatusText(step.consignmentStatus)}
              </p>
            </div>
            {index !== steps.length - 1 && (
              <div
                className="flex-grow h-1 bg-[#63b888]"
                style={{ marginLeft: "-3.5rem", marginRight: "-5.3rem" }}
              ></div>
            )}
          </div>
        ))}
      </div>
    );
  };
  const userName = userInfo
    ? `${userInfo.firstName} ${userInfo.lastName}`
    : "Guest";
  const handleReceivedOrderClick = (item) => {
    setFormData({
      consignmentId: item.consignmentId,
      userName: userName || "",
      userId: userInfo?.code || "",
      userMobile: userInfo?.mobile || "",
      statusName: "Received by CB Team",
      status: "6",
      createdBy: userInfo?.code || "",
      modifiedBy: userInfo?.code || "",
    });
    console.log("Received Order Clicked", item);
    setIsVisible(true);
  };

  // const handleSubmit = () => {
  //     setLoading(true);
  //     // Simulate form submission
  //     setTimeout(() => {
  //         setLoading(false);
  //         setIsVisible(false);
  //     }, 2000);
  // };

  const [isMedicineModalVisible, setMedicineModalVisible] = useState(false);
  const [selectedMedicineList, setSelectedMedicineList] = useState([]);
  const handleMedicineDetailsClick = (drugOrderList) => {
    setSelectedMedicineList(drugOrderList || []);
    setMedicineModalVisible(true);
  };

  const closeMedicineModal = () => {
    setMedicineModalVisible(false);
    setSelectedMedicineList([]);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        `${API_COMMONURL}DrugOrder/statusDelivered`,
        {
          method: "PUT", // Use POST method
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      console.log(response, "dxcfgvhbjnkml");
      if (response.status === 200) {
        toast.success("Order Received By Partner!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        console.log("Status update failed", response.data.message);
        toast.error(response.data.message || "Failed to update status");
      }
    } catch (error) {
      console.log("Error submitting data:", error);
    } finally {
      setLoading(false); // Stop the loading spinner
      setIsVisible(false); // Close the modal after submission
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <OurLayouts>
        <div className="mt-[102px] font-semibold text-lg">
          Order Tracking
          <div className="pt-[18px] pr-[40px]">
            {orderItems.length > 0 &&
              orderItems.map((item, idx) => (
                <div
                  key={idx}
                  className="bg-white shadow-md rounded-lg p-6 mb-4"
                  style={{ border: "1px solid #cccccc59" }}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <img
                        src={Drug}
                        alt="consignment icon"
                        className="h-10 w-10 mr-4"
                      />

                      <p className="text-gray-700 font-bold">
                        Consignment ID:{" "}
                        <span className="text-gray-900 font-medium">
                          {item.consignmentId}
                        </span>
                      </p>
                    </div>

                    <p
                      className="text-gray-700 font-bold pl-[320px] cursor-pointer"
                      onClick={() =>
                        handleMedicineDetailsClick(item.drugOrderList)
                      }
                    >
                      View Medicine Details ➔
                    </p>

                    <button
                      onClick={() => toggleStatusCollapse(idx)}
                      className="p-2"
                    >
                      {isStatusCollapsed[idx] ? (
                        <ChevronUpIcon className="h-5 w-5" />
                      ) : (
                        <ChevronDownIcon className="h-5 w-5" />
                      )}
                    </button>
                  </div>

                  <hr className="my-4 border-t-2 border-[#E0F7FA] " />

                  <div className="mt-7 flex justify-between items-center">
                    <button
                      className="text-[#18406E] font-semibold underline"
                      onClick={() => toggleStatusCollapse(idx)}
                    >
                      {isStatusCollapsed[idx]
                        ? "Hide Order Status"
                        : "Check Order Status"}
                    </button>
                  </div>

                  {isStatusCollapsed[idx] && (
                    <div className="mt-6">
                      {item.trackingStatus ? (
                        renderProgressBar(item.trackingStatus)
                      ) : (
                        <p className="text-gray-500">
                          No tracking information available for this
                          consignment.
                        </p>
                      )}
                    </div>
                  )}

                  {item?.trackingStatus?.length > 0 &&
                    item?.trackingStatus[item.trackingStatus.length - 1]
                      .consignmentStatus === 5 && (
                      <div className="mt-10 flex justify-center">
                        <button
                          onClick={() => handleReceivedOrderClick(item)}
                          className="bg-[#18406E] text-white px-4 py-2 rounded-full w-[40%] text-base"
                        >
                          Received Order
                        </button>
                      </div>
                    )}
                </div>
              ))}

            {error && <p className="text-red-500 mt-4">{error}</p>}

            <Modal
              isOpen={isVisible}
              onRequestClose={() => setIsVisible(false)}
              contentLabel="Received Order Modal"
              style={{
                overlay: { backgroundColor: "rgba(0, 0, 0, 0.7)" }, // Darker background for focus
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  maxWidth: "500px",
                  width: "100%",
                  backgroundColor: "#fff",
                  minHeight: "300px",
                },
              }}
            >
              <div className="modal-content">
                <h2 className="text-lg font-bold mb-4">
                  Received Order Details
                </h2>
                <div className="input-group mb-3">
                  <label className="font-medium">Consignment ID: </label>
                  <input
                    type="text"
                    value={formData.consignmentId}
                    readOnly
                    className="input"
                  />
                </div>
                <div className="input-group mb-3">
                  <label className="font-medium">User Name: </label>
                  <input
                    type="text"
                    value={formData.userName}
                    onChange={(e) =>
                      setFormData({ ...formData, userName: e.target.value })
                    }
                    className="input"
                  />
                </div>
                <div className="input-group mb-3">
                  <label className="font-medium">User ID: </label>
                  <input
                    type="text"
                    value={formData.userId}
                    readOnly
                    className="input"
                  />
                </div>
                <div className="input-group mb-3">
                  <label className="font-medium">User Mobile: </label>
                  <input
                    type="text"
                    value={formData.userMobile}
                    onChange={(e) =>
                      setFormData({ ...formData, userMobile: e.target.value })
                    }
                    className="input"
                  />
                </div>

                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div className="flex justify-center gap-4 mt-7">
                    <button
                      onClick={() => setIsVisible(false)}
                      className="bg-[#18406E] w-40 text-white py-2 px-4 rounded-full hover:bg-[#0f2b4d] transition duration-300 ease-in-out"
                      aria-label="Close Medicine Modal"
                    >
                      Close
                    </button>
                    <button
                      onClick={handleSubmit}
                      className="bg-[#18406E] w-40 text-white py-2 px-4 rounded-full hover:bg-[#0f2b4d] transition duration-300 ease-in-out"
                      aria-label="Submit Medicine Modal"
                    >
                      Submit
                    </button>
                  </div>
                )}

                {/* <button onClick={() => setIsVisible(false)} className="mt-4 bg-gray-500 text-white px-4 py-2 rounded">Close</button> */}
              </div>
            </Modal>
            <Modal
              isOpen={isMedicineModalVisible}
              onRequestClose={closeMedicineModal}
              contentLabel="Medicine Details"
              style={{
                overlay: { backgroundColor: "rgba(0, 0, 0, 0.7)" }, // Darker background for focus
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  maxWidth: "500px",
                  width: "100%",
                  backgroundColor: "#fff",
                  minHeight: "600px",
                  overflowY: "auto",
                },
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h2
                  style={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    marginBottom: "16px",
                    color: "#333",
                  }}
                >
                  Medicine Details
                </h2>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    marginBottom: "16px",
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "#f8f8f8",
                        borderBottom: "2px solid #ddd",
                      }}
                    >
                      <th
                        style={{
                          padding: "12px",
                          textAlign: "left",
                          fontSize: "1rem",
                          color: "#555",
                        }}
                      >
                        Medicine Name
                      </th>
                      <th
                        style={{
                          padding: "12px",
                          textAlign: "left",
                          fontSize: "1rem",
                          color: "#555",
                        }}
                      >
                        Price(₹)
                      </th>
                      <th
                        style={{
                          padding: "12px",
                          textAlign: "left",
                          fontSize: "1rem",
                          color: "#555",
                        }}
                      >
                        Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedMedicineList.map((medicine, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: "1px solid #ddd" }}
                      >
                        <td
                          style={{
                            padding: "12px",
                            fontSize: "1rem",
                            color: "green",
                          }}
                        >
                          {medicine.drugName || "N/A"}
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            fontSize: "1rem",
                            color: "green",
                          }}
                        >
                          {medicine.modifiedPrice || "N/A"}
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            fontSize: "1rem",
                            color: "green",
                          }}
                        >
                          {medicine.modifiedQuantity || "N/A"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  onClick={closeMedicineModal}
                  className="bg-[#18406E] w-80 text-white py-2 px-4 rounded-full hover:bg-[#0f2b4d] transition duration-300 ease-in-out"
                  style={{ alignSelf: "center", marginTop: "300px" }}
                  aria-label="Close Medicine Modal"
                >
                  Close
                </button>
              </div>
            </Modal>
          </div>
        </div>
      </OurLayouts>
    </div>
  );
};

export default OrderTracking;
