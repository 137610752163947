import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OurLayouts from '../layouts/index';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { APP_ROUTES } from '../../constants/approutes';
import Search from "../../assets/search.svg";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState(''); 
  const pageSize = 50;
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchData = async (page = 1, query = '') => {
    setLoading(true);
    const userCode = localStorage.getItem('userCode');
    const roleCode = localStorage.getItem('roleCode');
    const status = '4';

    const requestBody = {
      onboardingStatus: status,
      pageSize: 50,
      pageNo: page,
      name: query,
    };

    // if (roleCode !== 'SBH' && roleCode !== 'FA') {
    //   requestBody.userCode = userCode;
    // }

    try {
      const response = await fetch(`${API_URL}Partner/getAll`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const result = await response.json();

      // Check if successList has data
      if (result.responseCode === '200') {
        const fetchedData = result.successList || [];
        setData(fetchedData);


        const totalRecords = fetchedData.length > 0 ? fetchedData[0].totalRecords : 0;
        setTotalPages(Math.ceil(totalRecords / pageSize)); // Assuming 10 records per page

        console.log('Total Records:', totalRecords); // Log the total records
      } else {
        toast.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('An error occurred while fetching data');
    } finally {
      setLoading(false);
    }

  };



  useEffect(() => {
    fetchData(currentPage, searchQuery);
  }, [currentPage, searchQuery]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const handleNavigate = (data) => {
    navigate(APP_ROUTES.DASHBOARD, {
      state: {
        partnerCode: data.code,
        id: data.id,
        data: data
      }
    });
  };
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="flex flex-col h-screen">
      <OurLayouts>
        <div className="flex flex-col w-full mt-[7rem] overflow-auto">
          <div className="flex justify-between items-center mb-8"> {/* Adjust alignment */}
            <h1 className="text-lg text-[#262626] font-semibold">Partner Names</h1>
            <div className="relative flex items-center mr-[130px] mt-[20px] ">
              <input
                type="text"
                value={searchQuery} 
                onChange={handleSearch}
                placeholder="Search by name..."
                className="border border-gray-300 rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-green-500 w-80"
              />
              <div className="absolute right-3">
                <img src={Search} alt="Search Icon" className="h-5 w-5 text-gray-400" />
              </div>
            </div>

          </div>

          {/* Loading Indicator */}
          {loading ? (
            <div className="flex justify-center min-h-[300px] items-center">
              <CircularProgress size={80} color="inherit" />
            </div>
          ) : (
            <>
              <table className="table-auto w-full bg-white shadow-lg rounded-lg">
                <thead className="bg-[#FAFAFA]">
                  <tr>
                    <th className="px-6 py-4 text-left text-[#004172] font-semibold">Partner Names</th>
                    <th className="px-6 py-4 text-left text-[#004172] font-semibold">Name</th>
                    <th className="px-6 py-4 text-left text-[#004172] font-semibold">Date</th>
                    <th className="px-6 py-4 text-left text-[#004172] font-semibold">District</th>
                    <th className="px-6 py-4 text-left text-[#004172] font-semibold">Partner ID</th>
                    <th className="px-6 py-4 text-left text-[#004172] font-semibold">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index} className="border-t border-t-[#E2E2E2]">
                      <td className="px-6 py-4 font-medium text-sm">{item.name}</td>
                      <td className="px-6 py-4 font-medium text-sm">{item.contactName}</td>
                      <td className="px-6 py-4 font-medium text-sm">{moment(item?.createdDate).format("DD-MM-YYYY")}</td>
                      <td className="px-6 py-4 font-medium text-sm">{item.district}</td>
                      <td className="px-6 py-4 font-medium text-sm">{item.code}</td>
                      <td
                        className="px-6 py-4 text-[#18406E] font-medium cursor-pointer"
                        onClick={() => handleNavigate(item)}
                      >
                        View ➔
                      </td>
                    </tr>
                  ))}
                </tbody>

              </table>

              {!loading && totalPages > 1 && (
                <div className="flex justify-center items-center mt-4 space-x-2 pb-4">
                  {/* Left Arrow */}
                  <button
                    className={`w-6 h-6 flex items-center justify-center rounded-full 
        ${currentPage === 1 ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-gray-200 text-black'}`}
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    &lt;
                  </button>

                  {/* Page Numbers */}
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      className={`w-6 h-6 flex items-center justify-center rounded-full 
          ${currentPage === index + 1 ? 'bg-green-500 text-white' : 'bg-gray-200 text-black'}`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}

                  {/* Right Arrow */}
                  <button
                    className={`w-6 h-6 flex items-center justify-center rounded-full 
        ${currentPage === totalPages ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-gray-200 text-black'}`}
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    &gt;
                  </button>
                </div>
              )}

            </>
          )}
        </div>
      </OurLayouts>
    </div>
  );
};

export default Dashboard;
