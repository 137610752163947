import React from "react";
import { APP_ROUTES } from "../constants/approutes";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../components/Login";
import Dashboard from "../components/Dashboard/index";
import Orderdashboard from "../components/Dashboard/Dashboard";
import Popup from "../components/Popup";
import Orderatracking from "../components/Dashboard/OrderaTracking";
import Allmedicine from "../components/Dashboard/Allmedicine";
import Medicineorder from "../components/Dashboard/Medicineorder";
import Reordermedicine from "../components/Dashboard/Reordermedicine";
import Pastorder from "../components/Dashboard/Pastorder";

// Private Route component
const PrivateRoute = ({ children }) => {
  const auth = checkIsLoggedIn();
  return auth ? children : navigateToLogin();
};

// Navigation to login page if not authenticated
const navigateToLogin = () => {
  localStorage.clear(); // Clear local storage
  return <Navigate to={APP_ROUTES.LOGIN} />; // Redirect to login page
};

// Check if the user is logged in
const checkIsLoggedIn = () => {
  const loginObjStr = localStorage.getItem("userCode");
  try {
    const userCode = loginObjStr || null;
    return userCode; // Return userCode if exists
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return null;
  }
};

const OurRoutes = () => {
  return (
    <Routes>
      {/* Default Route */}
      <Route path="/" element={<Navigate to={APP_ROUTES.LOGIN} />} />

      {/* Public Routes */}
      <Route path={APP_ROUTES.LOGIN} element={<Login />} />

      {/* Private Routes */}
      <Route
        path={APP_ROUTES.DASHBOARD}
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.DASHBOARDORDER}
        element={
          <PrivateRoute>
            <Orderdashboard />
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.POPUP}
        element={
          <PrivateRoute>
            <Popup />
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.ORDERTRACKING}
        element={
          <PrivateRoute>
            <Orderatracking />
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.ALLMEDICINE}
        element={
          <PrivateRoute>
            <Allmedicine />
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.MEDICINEORDER}
        element={
          <PrivateRoute>
            <Medicineorder />
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.REORDERMEDICINE}
        element={
          <PrivateRoute>
            <Reordermedicine />
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.PASTORDER}
        element={
          <PrivateRoute>
            <Pastorder />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export { OurRoutes };
