import CryptoJS from 'crypto-js';

export function encryptData(data) {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_IV);

  if (typeof data !== 'string') {
    data = JSON.stringify(data);
  }

  const cipherText = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  console.log("Before saving:", data);
  return { data: cipherText.toString() };
}

export function decryptData(cipherText) {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_IV);

  const bytes = CryptoJS.AES.decrypt(cipherText, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  let originalText = bytes.toString(CryptoJS.enc.Utf8);
  try {
    originalText = JSON.parse(originalText);
  } catch (error) {
    console.error('Error parsing JSON:', error);
  }

  return originalText;
}
