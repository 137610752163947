import React, { useState, useEffect } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import OurLayouts from "../layouts/index";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import Search from "../../assets/search.svg";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import NoMedicine from "../../assets/NoMedicine.svg";
import debounce from "lodash.debounce";
import DeleteIcon from "../../assets/DeleteIcon.svg";
import Medicine from "../../assets/Medicine.svg";
import { APP_ROUTES } from "../../constants/approutes";
import Popup from "../../components/Popup";
import EditIcon from "../../assets/Editicon.svg";
import View from "../../assets/Viewicon.svg";
import Ongoingedit from "./Ongoingedit";
import { FaFilter } from "react-icons/fa";
import { Calendar } from "primereact/calendar";
import ViewDetails from "./ViewDetails";
import Orderatracking from "./OrderaTracking";

// import "primereact/resources/themes/saga-blue/theme.css";
// import "primereact/resources/primereact.min.css";
// import "primeicons/primeicons.css";

const Index = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleSecondSidebar, setVisibleSecondSidebar] = useState(false);
  const [activedraftTab, setActivedraftTab] = useState(0);
  const API_URL = process.env.REACT_APP_API_URL;
  const API_ES_URL = process.env.REACT_APP_API_ES_URL;

  const location = useLocation();
  const passedData = location.state?.data;
  const roleCode = localStorage.getItem("roleCode");
  const [visibleedit, setVisibleEdit] = useState(false);
  const tabs = ["Completed", "Ongoing", "Draft"];
  const drafttabs = ["Success List", "Error List"];
  const [selectedOrder, setSelectedOrder] = useState(null);
  const pageSize = 50;
  const userCode = localStorage.getItem("userCode");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [statusdata, setStatusdata] = useState([]);
  const [filterstatus, setFilterstatus] = useState(null);
  const [deletedata, setDeletedata] = useState("");
  const [editdata, setEditdata] = useState("");
  const [cartiddata, setCartiddata] = useState("");
  const [isDivVisible, setIsDivVisible] = useState(false);
  const partnerCode = location.state?.partnerCode;
  // const retailerName = location.state?.retailerName;
  const retailerName = localStorage.getItem("retailerName");
  React.useEffect(() => {
    if (partnerCode) {
      console.log("retailerName", partnerCode, retailerName);
    }
  }, [partnerCode]);
  const formatDate = (date) => {
    if (!date) return "";

    // Adjust for local time
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getDate()).padStart(2, "0"); // Get local day

    return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
  };
  const handleSearch = () => {
    if (startDate && !endDate) {
      alert("Please select the end date.");
      return;
    } else if (!startDate && endDate) {
      alert("Please select the start date.");
      return;
    }
    if (startDate && endDate) {
      if (endDate <= startDate) {
        alert("End date cannot be before or equal to the start date.");
        return;
      }
    }
    fetchOrders(false);
  };
  const handleRefresh = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterstatus("");
    // setPage(1);
    fetchOrders(true);
  };

  const [statusTextMap, setStatusTextMap] = useState({});

  const getStatus = async () => {
    try {
      const response = await fetch(`${API_URL}dropdown/getOrderStatus/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      console.log("vgjhbknlk", data);

      if (data.responseCode === "200" && data.successList) {
        setStatusdata(data.successList);
        const statusMap = data.successList.reduce((map, status) => {
          map[status.key] = status.description;
          return map;
        }, {});

        setStatusTextMap(statusMap);
        console.log("Statusmopooo", statusMap);
      } else {
        console.error("Failed to fetch order statuses");
      }
    } catch (error) {
      console.error("Error fetching credit periods:", error);
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  // const statusTextMap = {
  //   1: "Order Enquiry Raised",
  //   2: "Waiting for Partner Confirmation",
  //   3: "Partner Rejected",
  //   4: "Partner Confirmed Order",
  //   5: "Packed",
  //   6: "Shipped",
  //   7: "Order on the Way",
  //   8: "Out for Delivery",
  //   9: "Delivered",
  // };

  const getStatusColor = (status) => {
    const statusColorMap = {
      1: "#0272DA",
      2: "#ECAC09",
      3: "#FF2929",
      4: "#51B955",
      5: "#2A8F2F",
      6: "#51B955",
      7: "#18406E",
      8: "#0272DA",
      9: "#51B955",
    };
    return statusColorMap[status] || "#9E9E9E";
  };

  const fetchOrders = async (Refresh) => {
    try {
      setLoading(true);
      let apiUrl = `${API_URL}order/getOrderDetails`; // Default API for Completed/Ongoing tabs
      const payload = {
        partnerCode: passedData?.code ? passedData?.code : partnerCode,
        pageSize: pageSize,
        pageNo: page,
        fromDate: Refresh ? null : formatDate(startDate),
        toDate: Refresh ? null : formatDate(endDate),
        orderStatus: Refresh ? null : filterstatus,
      };

      if (activeTab === 0) {
        payload.orderStatus = 7; // For completed orders
      }

      if (activeTab === 2) {
        apiUrl = `${API_URL}order/getCartDetails`; // Use different API for Draft tab
      }

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      console.log(data, "guhjkl");
      if (data && data.successList) {
        setOrders(data.successList);
        setTotalRecords(data.successList[0]?.totalRecords || 0);
      } else {
        setOrders([]);
      }
    } catch (err) {
      toast.error("An error occurred while fetching order details.");
    } finally {
      setLoading(false);
    }
  };

  const [ordersLimited, setOrdersLimited] = useState([]);
  const fetchLimitedOrders = async () => {
    try {
      const payload = {
        partnerCode: passedData?.code ? passedData?.code : partnerCode,
        orderStatus: 4,
      };

      const response = await fetch(`${API_URL}order/getOrderDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`, // Ensure token is available
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data && data.successList) {
        setOrdersLimited(data.successList.slice(0, 5));
      } else {
        setOrdersLimited([]);
      }
    } catch (err) {
      console.error("Error fetching limited orders:", err);
    }
  };

  const handleCreateNewClick = async () => {
    try {
      await fetchLimitedOrders();

      clearForm();

      setVisibleSecondSidebar(true);
    } catch (err) {
      console.error("Error fetching limited orders:", err);
    }
  };

  // const fetchPrevious = async () => {
  //   try {
  //     const code = localStorage.getItem("partnerCode");
  //     console.log(code, "partnerCode from localStorage");

  //     const payload = {
  //       partnerCode: code,
  //       orderStatus: 4,
  //     };
  //     console.log(payload, "Payload being sent to the API");

  //     const response = await axios.post(
  //       `${configAPI.API_URL}order/getOrderDetails`,
  //       payload,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`, // Ensure token is available
  //         },
  //       }
  //     );

  //     if (response.data && response.data.successList) {
  //       setOrders(response.data.successList.slice(0, 5)); // Set orders in your state
  //     } else {
  //       setOrders([]); // Set an empty array if no orders are returned
  //     }
  //   } catch (err) {
  //     console.error("Error fetching orders:", err);
  //   } finally {

  //   }
  // };

  useEffect(() => {
    fetchOrders(false);
  }, [activeTab, page, filterstatus]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(1); // Reset to first page when the tab changes
  };

  const handleTabdraftChange = (event, newValue) => {
    setActivedraftTab(newValue);
    // setPage(1); // Reset to first page when the tab changes
  };

  const handlePageChange = (event, value) => {
    setPage(value); // Update page number and trigger fetch
  };

  const [searchResults, setSearchResults] = useState([]);
  const [addManually, setAddManually] = useState(false);
  const [name, setName] = useState("");
  const [medicineTypeOfSell, setMedicineTypeOfSell] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [buy, setBuy] = useState("");
  const [getFree, setGetFree] = useState("");
  const [composition, setComposition] = useState("");
  const [errors, setErrors] = useState({});
  const [drugForms, setDrugForms] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [unit, setUnit] = useState("");
  const [description, setDescription] = useState("");
  const [addedMedicines, setAddedMedicines] = useState([]);

  const [discount, setDiscount] = useState(0);
  const [cart_id, setCart_id] = useState("");
  const [isBulkOrderOpen, setIsBulkOrderOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [iddata, setdataid] = useState("");
  const [isdrugCategory, setDrugCategory] = useState("");

  const handleBulkOrderClick = () => {
    setIsBulkOrderOpen(true);
  };

  const handleCloseBulkOrder = () => {
    setIsBulkOrderOpen(false);
  };
  const handleSubmitBulkOrder = () => {
    setIsBulkOrderOpen(false);
  };

  // console.log(addedMedicines, "addedMedicinesvv")

  const navigate = useNavigate();

  // const handleBulkOrderClick = () => {
  //   navigate(APP_ROUTES.POPUP);
  // };

  const fetchDrugForms = async () => {
    try {
      const response = await fetch(`${API_URL}dropdown/getDrugForm`);
      const data = await response.json();
      if (data && data.successList) {
        const formattedData = data.successList.map((item) => ({
          label: item.key,
          value: item.description,
        }));
        setDrugForms(formattedData);
      }
    } catch (error) {
      console.error("Error fetching drug forms:", error);
    }
  };

  const fetchMedicines = async () => {
    try {
      if (searchInput.length < 4) {
        setSearchResults([]);
        toast.error(
          "You need to enter a minimum of 4 letters of the medicine to search."
        );
        return;
      }
      setLoading(true);
      const response = await fetch(
        `${API_ES_URL}medicine?freeTextSearch=${searchInput}&pageNo=1&pageSize=20`
      );
      const data = await response.json();
      if (data && data.length > 0) {
        setSearchResults(data);
        setAddManually(false);
      } else {
        setSearchResults([]);
        // setAddManually(true);
      }
    } catch (error) {
      console.error("Error fetching medicines:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchMedicines = debounce(fetchMedicines, 300);

  useEffect(() => {
    fetchDrugForms();
  }, []);

  useEffect(() => {
    if (searchInput.length >= 4) {
      debouncedFetchMedicines();
    } else {
      setSearchResults([]);
    }
  }, [searchInput]);

  const handleSave = () => {
    const newErrors = {};

    if (!price) newErrors.price = "Price is required";
    if (!quantity) newErrors.quantity = "Quantity is required";
    if (addManually && !medicineTypeOfSell)
      newErrors.medicineTypeOfSell = "Drug form is required";
    if (!buy && getFree) newErrors.buy = "Buy is required";
    if (!getFree && buy) newErrors.getFree = "Get Free is required";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;

    const newItem = {
      id: selectedItemId ? selectedItemId : "",
      description,
      name: name.trim(),
      unit,
      price,
      quantity,
      medicineTypeOfSell,
      buy,
      getFree,
      composition,
      addManually,
      isdrugCategory,
    };

    const isItemAlreadyAdded = addedMedicines.some((existingItem) => {
      const isSameId = newItem.id && existingItem.id === newItem.id;
      const isSameName =
        existingItem.name.trim().toLowerCase() === newItem.name.toLowerCase();
      return isSameId || isSameName;
    });

    if (isItemAlreadyAdded) {
      toast.error("Duplicate Medicine. This medicine has already been added.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setAddedMedicines((prevMedicines) => {
      // Remove the draft item first
      const updatedMedicines = prevMedicines.filter(
        (item) => item.itemId !== deletedata
      );

      // Then add the new item
      return [...updatedMedicines, newItem];
    });

    // Clear the form and update visibility
    clearForm();
    setVisibleSecondSidebar(false);
    setVisibleRight(true);

    // setAddedMedicines((prevMedicines) => [...prevMedicines, newItem]);

    // clearForm();
    // setVisibleSecondSidebar(false);
    // setVisibleRight(true);
    // deletedraftItem(deletedata);
  };
  useEffect(() => {
    if (addedMedicines.length == 1 && !editdata) {
      handlesaveSubmit(true);
      console.log(addedMedicines, addedMedicines.length, "edtrfyguhijo");
    }
  }, [addedMedicines]);
  const clearForm = () => {
    setName("");
    setMedicineTypeOfSell("");
    setPrice("");
    setQuantity("");
    setBuy("");
    setGetFree("");
    setComposition("");
    setSelectedItemId("");
    setAddManually(false);
    setDrugCategory("");
  };

  const handleItemClick = (selectedItem) => {
    console.log(selectedItem, "sredtfhujio");
    setName(selectedItem.medicineName); // Set the selected medicine name
    setMedicineTypeOfSell(selectedItem.medicineTypeOfSell);
    setPrice(selectedItem.medicineRate);
    setComposition(selectedItem.composition);
    setSearchResults([]);
    setAddManually(false);
    setSelectedItemId(selectedItem.id);
    setSearchInput("");
    setDrugCategory(selectedItem.drugCategory);
  };

  const handleAddClick = () => {
    setName("");
    setMedicineTypeOfSell("");
    setPrice("");
    setComposition("");
    setSearchResults([]);
    setAddManually(true);
    setSelectedItemId("");
    setSearchInput("");
    setDrugCategory("");
  };

  const deleteItem = (id) => {
    const updatedItems = addedMedicines.filter((item) => item.id !== id);
    setAddedMedicines(updatedItems);
  };
  const deletedraftItem = (itemId) => {
    const updatedItems = addedMedicines.filter(
      (item) => item.itemId !== itemId
    );
    setAddedMedicines(updatedItems);
  };

  const edititemdraft = (id) => {
    // deleteItem(id);
    setVisibleSecondSidebar(true);
    setDeletedata(id);
  };

  // Function to calculate subtotal
  const calculateSubtotal = () => {
    return addedMedicines.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
  };

  // Function to calculate discount
  const calculateDiscount = (subtotal) => {
    return (subtotal * discount) / 100;
  };

  // Function to calculate total (correct formula)
  const calculateTotal = (subtotal, discountAmount) => {
    return subtotal - discountAmount; // Correct calculation
  };

  // Perform calculations
  const subtotal = calculateSubtotal();
  const discountAmount = calculateDiscount(subtotal);
  const total = calculateTotal(subtotal, discountAmount);

  // Increment or decrement quantity
  const handleInputChange = (medicineId, value) => {
    const numericValue = Number(value);
    if (isNaN(numericValue) || numericValue < 1) return; // Prevent invalid values like negative or non-numeric
    setAddedMedicines((prevMedicines) =>
      prevMedicines.map((medicine) =>
        medicine.id === medicineId
          ? { ...medicine, quantity: numericValue }
          : medicine
      )
    );
  };

  // Increment or decrement quantity
  const handleQuantityChange = (medicineId, delta) => {
    setAddedMedicines((prevMedicines) =>
      prevMedicines.map((medicine) =>
        medicine.id === medicineId
          ? {
              ...medicine,
              quantity: Math.max(1, Number(medicine.quantity) + delta),
            }
          : medicine
      )
    );
  };

  const createPayload = () => {
    const drugOrderDetailsList = addedMedicines?.map((item) => {
      const quantity = Number(item?.quantity);
      const buy = Number(item?.buy) ? Number(item?.buy) : 1;
      const getFree = Number(item?.getFree) ? Number(item?.getFree) : 0;

      const quotient = Math.floor(quantity / buy);
      const totalQuantity = quotient * getFree + quantity;
      const freeQuantity = quotient * getFree;

      const drugDetails = {
        partnerCode: passedData?.code ? passedData?.code : partnerCode,
        unitPrice: parseFloat(item?.price),
        actualAmount: parseFloat(item?.price) * parseInt(item?.quantity),
        discountAmount: "",
        discountPercent: "",
        buyQuantity: item?.buy,
        getQuantity: item?.getFree,
        totalAmount: parseFloat(item?.price) * parseInt(item?.quantity),
        quantity: parseInt(item?.quantity),
        drugCode: item?.id,
        drugName: item?.name,
        drugTypeOfSell: item?.medicineTypeOfSell,
        drugComposition:
          item?.composition === undefined ? "" : item?.composition,
        drugSearchAvailability: item?.addManually === false ? "Y" : "N",
        totalQuantity: totalQuantity,
        freeQuantity: freeQuantity,
        drugSearchAvailability: item?.drugSearchAvailability,
        drugCategory: item?.isdrugCategory
          ? item?.isdrugCategory
          : item?.drugCategory,
      };

      return drugDetails;
    });
    console.log(addedMedicines, "tytguijo");
    const payload = {
      partnerCode: passedData?.code ? passedData?.code : partnerCode,
      userCode: userCode,
      actualAmount: subtotal.toFixed(2),
      discountAmount: discountAmount,
      discountPercent: discount.toString(),
      totalAmount: total.toFixed(2),
      cartId: cartiddata,
      drugOrderDetailsList: drugOrderDetailsList,
      id: iddata,
      sourceChannel: "WEB",
    };

    return payload;
  };

  const [isSavingDraft, setIsSavingDraft] = useState(false); // State for loader on "Save Draft"
  const determineUrl = () => {
    if (activeTab == 2 && editdata) {
      return "order/updateStatus";
    } else {
      return "order/saveCartDetails";
    }
  };
  const handlesaveSubmit = async () => {
    console.log(addedMedicines, "dtfyguhyoikpl");

    const payload = createPayload();

    try {
      const response = await fetch(`${API_URL}order/saveCartDetail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      setEditdata(false);
      if (!response.ok) {
        throw new Error("Failed to save draft");
      }

      const result = await response.json();
      console.log("resultdata", result);
      // setCart_id(result.successObject.CartId);
      // setVisibleRight(false);

      // toast.success("Draft saved successfully!", {
      //   position: "top-right",
      //   autoClose: 5000, // Toast will auto-close after 5 seconds
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      // setActiveTab(2);
      // localStorage.setItem("activeTab", "2");

      // // Add a small delay and reload the page
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      // console.error("Error saving draft:", error);
      // toast.error("An error occurred while saving the draft.", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    } finally {
      setIsSavingDraft(false); // Hide loader after completion
    }
  };
  const saveDraft = async () => {
    const url = determineUrl();

    setIsSavingDraft(true); // Show loader

    const payload = createPayload();

    try {
      const response = await fetch(`${API_URL}${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      setEditdata(false);
      if (!response.ok) {
        throw new Error("Failed to save draft");
      }

      const result = await response.json();
      console.log("Draft saved successfully:", result);

      setVisibleRight(false);

      toast.success("Draft saved successfully!", {
        position: "top-right",
        autoClose: 5000, // Toast will auto-close after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setActiveTab(2);
      localStorage.setItem("activeTab", "2");

      // Add a small delay and reload the page
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Error saving draft:", error);

      toast.error("An error occurred while saving the draft.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSavingDraft(false); // Hide loader after completion
    }
  };

  const [isProceeding, setIsProceeding] = useState(false);

  const handleProceed = async () => {
    setIsProceeding(true); // Show loader

    const payload = createPayload();
    console.log(payload, "xdtfyguhij");
    try {
      const response = await fetch(`${API_URL}order/createOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to create order");
      }

      const result = await response.json();
      console.log("Order created successfully:", result);

      // Close the sidebar
      setVisibleRight(false);

      // Show success notification
      toast.success("Order created successfully!", {
        position: "top-right",
        autoClose: 5000, // Toast will auto-close after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setActiveTab(1);
      localStorage.setItem("activeTab", "1");

      // Add a small delay and reload the page
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Error creating order:", error);

      // Show error notification
      toast.error("An error occurred while creating the order.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsProceeding(false); // Hide loader after completion
    }
  };

  useEffect(() => {
    const savedActiveTab = localStorage.getItem("activeTab");

    if (savedActiveTab) {
      setActiveTab(parseInt(savedActiveTab, 10));
      localStorage.removeItem("activeTab");
    }
  }, []);

  const addItem = () => {
    clearForm();

    setVisibleSecondSidebar(true);

    setVisibleRight(true);
  };
  const handleorderEdit = async (order) => {
    setVisibleEdit(true);
    setSelectedOrder(order);
  };

  const [visibleview, setVisibleView] = useState(false);
  const handleView = async (order) => {
    setVisibleView(true);
    setSelectedOrder(order);
  };
  const [visibletrack, setVisibletrack] = useState(false);
  const handleTrackOrder = async (order) => {
    // setVisibletrack(true);
    setSelectedOrder(order);
    navigate(APP_ROUTES.ORDERTRACKING, {
      state: {
        order: order,
        activeTab: activeTab, // Current active tab
      },
    });
  };

  const handleEdit = async (item) => {
    console.log("Clicked");
    console.log(item, "tgggggy");
    setEditdata(true);

    try {
      const response = await fetch(`${API_URL}order/getCartItemDetails`, {
        method: "POST", // Use POST method
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          partnerCode: item?.partnerCode,
          cartId: item?.cartId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch order details"); // Handle non-200 responses
      }

      const data = await response.json(); // Parse the JSON response
      console.log(data, "responseee");

      if (data && data.successList) {
        console.log(data.successList, "respiiuughghgh");
        if (data.successList.length > 0) {
          setDiscount(item?.discountPercent ? item?.discountPercent : 0);
          setCartiddata(item?.cartId ? item?.cartId : "");
          setdataid(item?.id ? item?.id : "");
          const fetchedItems = data.successList.map((item) => ({
            itemId: item.id,
            id: item.drugCode,
            name: item.drugName,
            price: item.unitPrice,
            buy: item?.buyQuantity,
            getFree: item?.getQuantity,
            quantity: item?.quantity,
            medicineTypeOfSell: item.drugTypeOfSell,
            composition: item.drugComposition,
            addManually: item.drugSearchAvailability === "N",
            drugSearchAvailability: item.drugSearchAvailability,
            drugCategory: item.drugCategory,
          }));

          setAddedMedicines(fetchedItems);

          setVisibleRight(true);
        }
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  console.log(cart_id, "responseeegghhuhhnh");

  const createnew = () => {
    setIsDivVisible(!isDivVisible);
  };
  const ViewOnhandle = (order) => {
    navigate(APP_ROUTES.PASTORDER, {
      state: {
        order: order,
        data: passedData,
        partnerCode: partnerCode,
      },
    });
  };
  const ReorderOnhandle = (order) => {
    navigate(APP_ROUTES.REORDERMEDICINE, {
      state: {
        order: order,
        data: passedData,
        partnerCode: partnerCode,
      },
    });
  };
  console.log(passedData, "rdyftugyuihoijpo");
  return (
    <div className="flex flex-col h-screen">
      <OurLayouts>
        <div className="flex flex-col w-full mt-[7rem] overflow-auto">
          <div className="container">
            <div className="flex items-center justify-between mb-8">
              <div className="flex flex-col items-start justify-between mb-8">
                <h1 className="text-2xl text-primary font-semibold">Orders</h1>
                <h2 className="text-base text-primary font-medium mt-4">
                  ({passedData?.name ? passedData?.name : retailerName})
                </h2>{" "}
                {/* Smaller font for passedData.name */}
              </div>

              <div className="flex space-x-4 mr-20 items-center">
                <div className="download-container flex items-center rounded-lg border border-gray-300">
                  <button
                    className="download-button text-sm  text-[#18406E] py-2 px-6 flex items-center hover:text-[#18406E] transition duration-300 focus:outline-none"
                    onClick={() =>
                      (window.location.href = `${process.env.REACT_APP_API_GOOGLE_URL}pharmacymdm1data.xlsx`)
                    }
                  >
                    <span className="download-icon mr-2">⬇️</span>
                    Download Medicine List
                  </button>
                  <div className="divider"></div>
                  <button
                    className="download-button  text-sm text-[#18406E] py-2 px-6 flex items-center hover:text-[#18406E] transition duration-300 focus:outline-none"
                    onClick={() =>
                      (window.location.href = `${process.env.REACT_APP_API_URL}excel/downloadExcelTemplate`)
                    }
                  >
                    <span className="download-icon mr-2">⬇️</span>
                    Download Template
                  </button>
                </div>

                <button
                  className="bg-[#3CBA84] text-white py-2 px-6 rounded-full border border-[#3CBA84] hover:bg-[#34A76D] transition duration-300 focus:outline-none"
                  onClick={handleBulkOrderClick}
                >
                  Bulk Order
                </button>
                <button
                  className="bg-[#3CBA84] text-white py-2 px-6 rounded-full border border-[#3CBA84] hover:bg-[#34A76D] transition duration-300 focus:outline-none"
                  onClick={handleCreateNewClick}
                >
                  +Create New
                </button>
              </div>

              <Sidebar
                visible={visibleRight}
                position="right"
                onHide={() => {
                  setVisibleRight(false);
                  window.location.reload();
                }}
                modal={true}
                style={{
                  width: addedMedicines.length === 0 ? "35vw" : "100vw",
                  height: "100vh",
                  zIndex: 1000,
                }} // Main sidebar is full-screen only when medicines are added
              >
                <div className="flex flex-col h-full p-4">
                  <div className="rounded-lg mb-[30px] px-[11px] pb-8 pt-3">
                    <div
                      className="rounded-lg mb-[30px] px-[11px] pb-8 pt-3"
                      style={{ boxShadow: "1px 1px 5px #ccc" }}
                    >
                      <div
                        className="flex justify-between pb-[6px] "
                        style={{ borderBottom: "2px solid #D7D7D7" }}
                      >
                        <h1 className="text-xl font-semibold text-[#3cba84] mb-4">
                          Add Medicine
                        </h1>
                        <div className="flex w-1/2">
                          <div className="w-1/5">
                            <h1
                              className="text-sm font-medium text-[#18406E] hover:[#18406E] cursor-pointer mt-[18px]"
                              onClick={handleAddClick}
                            >
                              + Add Manually
                            </h1>
                          </div>
                          <div className="w-4/5">
                            <div
                              className="flex items-center bg-white rounded-full mb-2 shadow-sm w-full"
                              style={{ border: "0.96px solid #544C4C24" }}
                            >
                              <input
                                type="text"
                                placeholder="Search medicine"
                                className="flex-grow px-4 py-2 text-sm text-gray-500 bg-white rounded-full focus:outline-none"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                              />
                              <div className="p-3">
                                <img
                                  src={Search}
                                  alt="Search Icon"
                                  className="h-6 w-6"
                                />
                              </div>
                            </div>

                            {loading ? (
                              <p>Loading...</p>
                            ) : searchResults.length > 0 ? (
                              <div
                                style={{
                                  padding: "9px 0px 0px 12px",
                                  position: "absolute",
                                  backgroundColor: "white",
                                  width: "37%",
                                  border: "1px solid #ccc",
                                }}
                              >
                                <ul>
                                  {searchResults.map((item) => (
                                    <li key={item.id}>
                                      <button
                                        onClick={() => handleItemClick(item)}
                                      >
                                        {item.medicineName}
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="p-fluid formgrid gap-4 mt-4 flex">
                        <div className="field w-[400px]">
                          <div className="flex items-center justify-between ">
                            <label
                              htmlFor=""
                              className="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Medicine Name{" "}
                              <span className="text-red-500">*</span>
                            </label>
                          </div>
                          <input
                            type="text"
                            id="medicineName"
                            className="p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            style={{ border: "0.96px solid #544C4C24" }}
                            value={name}
                            placeholder="Medicine Name"
                            readOnly={!addManually}
                            onChange={(e) => setName(e.target.value)}
                          />
                          {selectedItemId && !addManually && (
                            <p className="pt-[10px]">
                              {selectedItemId} | {medicineTypeOfSell}
                            </p>
                          )}
                        </div>

                        {/* {selectedItemId && !addManually && (
                        <>
                          <div className="field col-2">
                            <label
                              htmlFor="drugCode"
                              className="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Drug Code
                            </label>
                            <input
                              type="text"
                              id="drugCode"
                              className="p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                              style={{ border: "0.96px solid #544C4C24" }}
                              value={selectedItemId}
                              readOnly
                              placeholder="Drug Code"
                            />
                          </div>

                          <div className="field">
                            <label
                              htmlFor="packType"
                              className="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Pack Type
                            </label>
                            <input
                              type="text"
                              id="packType"
                              className="p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                              style={{ border: "0.96px solid #544C4C24" }}
                              value={medicineTypeOfSell}
                              readOnly
                              placeholder="Pack Type"
                            />
                          </div>
                        </>
                      )} */}

                        {/* Conditional Render for Manual Input */}
                        {addManually && (
                          <div className="field">
                            <label
                              htmlFor="drugForm"
                              className="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Drug Form <span className="text-red-500">*</span>{" "}
                              {/* Add the required asterisk */}
                            </label>
                            <select
                              id="drugForm"
                              className={`p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                                errors.medicineTypeOfSell
                                  ? "border-red-500"
                                  : "" // Apply red border if there's an error
                              }`}
                              style={{ border: "0.96px solid #544C4C24" }}
                              value={medicineTypeOfSell}
                              onChange={(e) =>
                                setMedicineTypeOfSell(e.target.value)
                              }
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              {drugForms.map((form) => (
                                <option key={form.id} value={form.value}>
                                  {form.label}
                                </option>
                              ))}
                            </select>
                            {errors.medicineTypeOfSell && (
                              <span className="text-red-500 text-sm mt-1">
                                {errors.medicineTypeOfSell}
                              </span>
                            )}
                          </div>
                        )}

                        <div className="field">
                          <label
                            htmlFor="quantity"
                            className="block text-sm font-medium text-gray-700 mb-2"
                          >
                            Quantity <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="number"
                            id="quantity"
                            className={`p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                              errors.quantity ? "border-red-500" : ""
                            }`}
                            style={{ border: "0.96px solid #544C4C24" }}
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            placeholder="Enter quantity"
                            onWheel={(e) => e.target.blur()}
                          />
                          {errors.quantity && (
                            <span className="text-red-500 text-sm mt-1">
                              {errors.quantity}
                            </span>
                          )}
                        </div>

                        <div className="field">
                          <label
                            htmlFor="price"
                            className="block text-sm font-medium text-gray-700 mb-2"
                          >
                            Price <span className="text-red-500">*</span>{" "}
                            {/* Add the required asterisk */}
                          </label>
                          <input
                            type="text"
                            id="price"
                            className={`p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                              errors.price ? "border-red-500" : "" // Apply red border if there's an error
                            }`}
                            style={{ border: "0.96px solid #544C4C24" }}
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            placeholder="Enter price"
                            readOnly={!addManually} // Ensure price is only editable when added manually
                          />
                          {errors.price && (
                            <span className="text-red-500 text-sm mt-1">
                              {errors.price}
                            </span> // Display error message if price is missing
                          )}
                        </div>

                        <div className="field">
                          <label
                            htmlFor="offer"
                            className="block text-sm font-medium text-gray-700 mb-2"
                          >
                            Any offer?
                          </label>
                          <div className="flex items-center space-x-4 w-[270px]">
                            <input
                              type="text"
                              id="buyOffer"
                              className={`p-inputtext p-component w-1/2 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                                errors.buy ? "border-red-500" : ""
                              }`}
                              style={{ border: "0.96px solid #544C4C24" }}
                              value={buy}
                              onChange={(e) => setBuy(e.target.value)}
                              placeholder="Buy"
                            />
                            <span>&</span>
                            <input
                              type="text"
                              id="getOffer"
                              className={`p-inputtext p-component w-1/2 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                                errors.getFree ? "border-red-500" : ""
                              }`}
                              style={{ border: "0.96px solid #544C4C24" }}
                              value={getFree}
                              onChange={(e) => setGetFree(e.target.value)}
                              placeholder="Get free"
                            />
                          </div>
                          {errors.buy && (
                            <span className="text-red-500 text-sm mt-1">
                              {errors.buy}
                            </span>
                          )}
                          {errors.getFree && (
                            <span className="text-red-500 text-sm mt-1">
                              {errors.getFree}
                            </span>
                          )}
                        </div>
                        <button
                          className="px-[22px] h-[40px] mt-[30px] bg-[#3cba84] text-white font-normal text-base rounded-full hover:bg-[#3cba84] transition duration-300"
                          onClick={handleSave}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  {addedMedicines.length === 0 ? (
                    <div className="flex-grow flex flex-col items-center justify-center">
                      <img
                        src={NoMedicine}
                        alt="No items added"
                        className="w-20 h-20 mb-4"
                      />
                      <h2 className="text-gray-500 font-medium">
                        No items added
                      </h2>
                    </div>
                  ) : (
                    <div className="w-full flex flex-col h-full">
                      <h2 className="text-xl font-semibold text-gray-800 mb-4">
                        Added Medicines
                      </h2>

                      {/* Medicines displayed in a scrollable table */}
                      <div className="flex-grow overflow-y-auto">
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={activedraftTab}
                            onChange={handleTabdraftChange}
                            TabIndicatorProps={{ sx: { height: "3px" } }}
                            sx={{ minHeight: "30px" }}
                            aria-label="customized tabs"
                          >
                            {drafttabs.map((tab, index) => (
                              <Tab
                                key={index}
                                label={tab}
                                sx={{
                                  textTransform: "none",
                                  fontWeight: "bold",
                                  fontSize: "1rem",
                                  color:
                                    activedraftTab === index
                                      ? "#4CAF50"
                                      : "#000000",
                                  minWidth: 100,
                                  padding: "6px 16px",
                                }}
                              />
                            ))}
                          </Tabs>
                        </Box>
                        <TableContainer
                          component={Paper}
                          style={{ width: "100%" }}
                        >
                          {activedraftTab == 0 && (
                            <Table aria-label="added medicines">
                              <TableHead className="bg-[#FAFAFA]">
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      color: "#004172",
                                    }}
                                  >
                                    Medicine Name
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      color: "#004172",
                                    }}
                                  >
                                    Type
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      color: "#004172",
                                    }}
                                  >
                                    Price (₹)
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      color: "#004172",
                                    }}
                                  >
                                    Quantity
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      color: "#004172",
                                    }}
                                  >
                                    Buy / Get Free
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      color: "#004172",
                                    }}
                                  >
                                    Total Price (₹)
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      color: "#004172",
                                    }}
                                  >
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {addedMedicines
                                  .filter(
                                    (medicine) =>
                                      medicine.drugSearchAvailability !== "U"
                                  ) // Filter out medicines with availability 'U'
                                  .map((medicine, index) => (
                                    <TableRow key={index}>
                                      <TableCell className="px-6 py-4 font-medium text-sm">
                                        {medicine.name}
                                      </TableCell>
                                      <TableCell className="px-6 py-4 font-medium text-sm">
                                        {medicine.medicineTypeOfSell}
                                      </TableCell>
                                      <TableCell className="px-6 py-4 font-medium text-sm text-right">
                                        {medicine.price}
                                      </TableCell>
                                      <TableCell className="px-6 py-4 font-medium text-sm">
                                        <div className="flex items-center space-x-2">
                                          <button
                                            className="border px-2 py-1 text-sm"
                                            onClick={() =>
                                              handleQuantityChange(
                                                medicine.id,
                                                -1
                                              )
                                            }
                                          >
                                            -
                                          </button>
                                          <input
                                            type="text"
                                            value={medicine.quantity}
                                            className="w-8 text-center border"
                                            onChange={(e) =>
                                              handleInputChange(
                                                medicine.id,
                                                e.target.value
                                              )
                                            }
                                          />
                                          <button
                                            className="border px-2 py-1 text-sm"
                                            onClick={() =>
                                              handleQuantityChange(
                                                medicine.id,
                                                1
                                              )
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                      </TableCell>
                                      <TableCell className="px-6 py-4 font-medium text-sm">
                                        {medicine.buy && medicine.getFree ? (
                                          <div>
                                            <span>
                                              Buy {medicine.buy}, Get{" "}
                                              {medicine.getFree} Free
                                            </span>
                                          </div>
                                        ) : (
                                          <span>No offer</span>
                                        )}
                                      </TableCell>
                                      <TableCell className="px-6 py-4 font-medium text-sm">
                                        {(
                                          medicine.price * medicine.quantity
                                        ).toFixed(2)}
                                      </TableCell>
                                      <TableCell className="px-6 py-4 font-medium text-sm">
                                        <button
                                          className="text-red-500"
                                          onClick={() =>
                                            deleteItem(medicine.id)
                                          }
                                        >
                                          <img
                                            src={DeleteIcon}
                                            alt="Delete"
                                            width={20}
                                            height={20}
                                          />
                                        </button>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          )}
                          {activedraftTab == 1 && (
                            <Table aria-label="added medicines">
                              <TableHead className="bg-[#FAFAFA]">
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      color: "#004172",
                                    }}
                                  >
                                    Medicine Name
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      color: "#004172",
                                    }}
                                  >
                                    Type
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      color: "#004172",
                                    }}
                                  >
                                    Price(₹)
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      color: "#004172",
                                    }}
                                  >
                                    Quantity
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      color: "#004172",
                                    }}
                                  >
                                    Buy / Get Free
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      color: "#004172",
                                    }}
                                  >
                                    Total Price
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      color: "#004172",
                                    }}
                                  >
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {addedMedicines
                                  .filter(
                                    (medicine) =>
                                      medicine.drugSearchAvailability == "U"
                                  ) // Filter out medicines with availability 'U'
                                  .map((medicine, index) => (
                                    <TableRow key={index}>
                                      <TableCell className="px-6 py-4 font-medium text-sm">
                                        {medicine.name}
                                      </TableCell>
                                      <TableCell className="px-6 py-4 font-medium text-sm">
                                        {medicine.medicineTypeOfSell}
                                      </TableCell>
                                      <TableCell className="px-6 py-4 font-medium text-sm">
                                        {medicine.price}
                                      </TableCell>
                                      <TableCell className="px-6 py-4 font-medium text-sm">
                                        <div className="flex items-center space-x-2">
                                          <button
                                            className="border px-2 py-1 text-sm"
                                            disabled={true}
                                            onClick={() =>
                                              handleQuantityChange(
                                                medicine.id,
                                                -1
                                              )
                                            }
                                          >
                                            -
                                          </button>
                                          <input
                                            type="text"
                                            value={medicine.quantity}
                                            className="w-8 text-center border"
                                            disabled={true}
                                            onChange={(e) =>
                                              handleInputChange(
                                                medicine.id,
                                                e.target.value
                                              )
                                            }
                                          />
                                          <button
                                            className="border px-2 py-1 text-sm"
                                            disabled={true}
                                            onClick={() =>
                                              handleQuantityChange(
                                                medicine.id,
                                                1
                                              )
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                      </TableCell>
                                      <TableCell className="px-6 py-4 font-medium text-sm">
                                        {medicine.buy && medicine.getFree ? (
                                          <div>
                                            <span>
                                              Buy {medicine.buy}, Get{" "}
                                              {medicine.getFree} Free
                                            </span>
                                          </div>
                                        ) : (
                                          <span>No offer</span>
                                        )}
                                      </TableCell>
                                      <TableCell className="px-6 py-4 font-medium text-sm">
                                        ₹
                                        {(
                                          medicine.price * medicine.quantity
                                        ).toFixed(2)}
                                      </TableCell>
                                      <TableCell className="px-6 py-4 font-medium text-sm">
                                        <button
                                          className="text-red-500"
                                          onClick={() =>
                                            deleteItem(medicine.id)
                                          }
                                        >
                                          <img
                                            src={DeleteIcon}
                                            alt="Delete"
                                            width={20}
                                            height={20}
                                          />
                                        </button>

                                        <button
                                          className="ml-3"
                                          onClick={() =>
                                            edititemdraft(medicine.itemId)
                                          }
                                        >
                                          <img
                                            src={EditIcon}
                                            alt="Edit"
                                            width={24}
                                            height={24}
                                          />
                                        </button>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          )}
                        </TableContainer>
                      </div>

                      {/* Sticky Pricing Summary and Action Buttons */}
                      <div className="flex justify-end ">
                        <div
                          className="bg-[#EEF5FD8A] p-4 rounded-lg mt-4 sticky bottom-0 w-1/2 "
                          style={{ position: "inherit" }}
                        >
                          <div className="flex justify-between mb-2">
                            <span className="text-sm font-medium">
                              Subtotal (₹)
                            </span>
                            <span className="text-sm font-medium">
                              {subtotal.toFixed(2)}
                            </span>
                          </div>
                          <div className="flex justify-between mb-2 mt-4">
                            <span className="text-sm font-medium">
                              Discount (%)
                            </span>
                            <input
                              type="text"
                              value={discount}
                              className="w-16 text-right border-b border-gray-400 text-sm font-medium"
                              onChange={(e) =>
                                setDiscount(Number(e.target.value))
                              }
                              maxLength={2}
                            />
                          </div>
                          <hr className="my-2" />
                          <div className="flex justify-between">
                            <span className="text-lg font-bold">Total</span>
                            <span className="text-lg font-bold">
                              ₹{total.toFixed(2)}
                            </span>
                          </div>

                          {/* Action Buttons */}
                          <div className="mt-6 flex justify-between items-center">
                            <div className="flex space-x-4">
                              <button
                                className="border border-[#004172] text-[#004172] py-1.5 px-4 rounded-full font-semibold"
                                style={{ fontSize: "14px", minWidth: "200px" }}
                                onClick={saveDraft}
                                disabled={isSavingDraft}
                              >
                                {isSavingDraft ? (
                                  <CircularProgress size={20} color="inherit" />
                                ) : (
                                  "Save Draft"
                                )}
                              </button>
                              {/* <button
                                className="border border-[#004172] text-[#004172] py-1.5 px-4 rounded-full font-semibold"
                                style={{ fontSize: "14px", minWidth: "200px" }}
                                onClick={addItem}
                              >
                                Add More
                              </button> */}
                            </div>
                            <button
                              className="bg-[#004172] text-white py-2 px-4 rounded-full font-semibold"
                              style={{
                                width: "auto",
                                minWidth: "200px",
                                fontSize: "14px",
                              }}
                              onClick={handleProceed}
                              disabled={isProceeding} // Disable the button while proceeding
                            >
                              {isProceeding ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                `Proceed (${addedMedicines.length} item${
                                  addedMedicines.length > 1 ? "s" : ""
                                })`
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* {addedMedicines?.length === 0 && (
                    <div className="p-4">
                      <Button
                        label="+ New Item"
                        className="w-full py-2 bg-[#004172] text-white font-normal text-base rounded-full hover:bg-[#004172] transition duration-300"
                        onClick={() => {
                          clearForm();
                          setVisibleRight(false);
                          setVisibleSecondSidebar(true);
                        }}
                      />
                    </div>
                  )} */}
                </div>
              </Sidebar>

              <Sidebar
                visible={visibleedit}
                position="right"
                onHide={() => setVisibleEdit(false)}
                modal={true}
                style={{
                  width: "100vw",
                  height: "100vh",
                  zIndex: 1000,
                }} // Main sidebar is full-screen only when medicines are added
              >
                <Ongoingedit
                  order={selectedOrder}
                  setActiveTab={setActiveTab}
                  setVisibleEdit={setVisibleEdit}
                />
              </Sidebar>

              <Sidebar
                visible={visibleview}
                position="right"
                onHide={() => setVisibleView(false)}
                modal={true}
                style={{
                  width: "100vw",
                  height: "100vh",
                  zIndex: 1000,
                }} // Main sidebar is full-screen only when medicines are added
              >
                <ViewDetails
                  order={selectedOrder}
                  setActiveTab={setActiveTab}
                  setVisibleView={setVisibleView}
                  activeTab={activeTab}
                />
              </Sidebar>

              {/* <Sidebar
                visible={visibletrack}
                position="right"
                onHide={() => setVisibletrack(false)}
                modal={true}
                style={{
                  width: "100vw",
                  height: "100vh",
                  zIndex: 1000,
                }} // Main sidebar is full-screen only when medicines are added
                
              >
                <Orderatracking
                  order={selectedOrder}
                  setActiveTab={setActiveTab}
                  setVisibletrack={setVisibletrack}
                  activeTab={activeTab}
                />
              </Sidebar> */}

              {/* Second Sidebar - Add New Item form */}
              <>
                <Sidebar
                  visible={visibleSecondSidebar}
                  position="right"
                  // onHide={() => setVisibleSecondSidebar(false)}
                  onHide={() => {
                    setVisibleSecondSidebar(false);
                    window.location.reload();
                  }}
                  modal={true}
                  style={{ width: "35vw", zIndex: 1000 }}
                >
                  {!isDivVisible && (
                    <>
                      <h2 className="text-lg font-semibold text-gray-800 mb-4">
                        Previous Orders
                      </h2>

                      {ordersLimited.length > 0 ? (
                        ordersLimited.map((order) => (
                          <div
                            key={order.orderId}
                            className="bg-white p-4 border rounded-lg shadow-sm flex flex-col space-y-2 mb-4"
                            style={{
                              borderColor: "#e0e0e06b",
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <svg
                                  width="20"
                                  height="14"
                                  viewBox="0 0 20 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_1060_544)">
                                    <path
                                      d="M17.7083 0H2.29172C1.02754 0 0 0.959036 0 2.13894V11.8611C0 13.041 1.02754 14 2.29172 14H17.7083C18.9725 14 20 13.041 20 11.8611V2.13894C20 0.959036 18.9725 0 17.7083 0ZM6.25 3.11106C7.39836 3.11106 8.33328 3.9838 8.33328 5.0556C8.33328 6.12726 7.39836 7 6.25 7C5.10164 7 4.16672 6.12726 4.16672 5.0556C4.16672 3.9838 5.10164 3.11106 6.25 3.11106ZM10 10.3056C10 10.6276 9.72 10.8889 9.375 10.8889H3.125C2.78 10.8889 2.5 10.6276 2.5 10.3056V9.91667C2.5 8.73676 3.52754 7.77773 4.79172 7.77773H7.70828C8.97246 7.77773 10 8.73676 10 9.91667V10.3056ZM16.875 10.8889H12.2917C11.9467 10.8889 11.6667 10.6276 11.6667 10.3056C11.6667 9.9836 11.9467 9.72227 12.2917 9.72227H16.875C17.22 9.72227 17.5 9.9836 17.5 10.3056C17.5 10.6276 17.22 10.8889 16.875 10.8889ZM16.875 7.77773H12.2917C11.9467 7.77773 11.6667 7.5164 11.6667 7.1944C11.6667 6.8724 11.9467 6.61106 12.2917 6.61106H16.875C17.22 6.61106 17.5 6.8724 17.5 7.1944C17.5 7.5164 17.22 7.77773 16.875 7.77773ZM16.875 4.66667H12.2917C11.9467 4.66667 11.6667 4.40533 11.6667 4.08333C11.6667 3.76133 11.9467 3.5 12.2917 3.5H16.875C17.22 3.5 17.5 3.76133 17.5 4.08333C17.5 4.40533 17.22 4.66667 16.875 4.66667Z"
                                      fill="#18406E"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1060_544">
                                      <rect
                                        width="20"
                                        height="14"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>

                                <span
                                  className="text-gray-600 font-semibold"
                                  style={{ marginLeft: "11px" }}
                                >
                                  Order Id - {order.orderId}
                                </span>
                              </div>
                            </div>

                            <span className="font-normal">
                              Order Date:{" "}
                              {moment(order.createdDate).format("DD-MM-YYYY")}
                            </span>
                            <span className="font-normal">
                              Total Amount (₹): {order.totalAmount}
                            </span>

                            <div className="flex gap-24 mt-2 justify-between">
                              <button
                                className="bg-[#004172] text-white py-2 px-6 rounded-full hover:bg-[#003355] transition duration-300 w-[179px]"
                                onClick={() => ViewOnhandle(order)}
                              >
                                View Details
                              </button>
                              <button
                                className="border border-[#004172] text-[#004172] py-2 px-6 rounded-full hover:bg-[#f1f1f1] transition duration-300 w-[179px]"
                                onClick={() => ReorderOnhandle(order)}
                              >
                                ReOrder
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="bg-white p-4  rounded-lg flex flex-col space-y-2">
                          <p className="text-gray-600">
                            No recent orders available
                          </p>
                        </div>
                      )}

                      <Button
                        label="+ New Item"
                        className="w-full py-2 mt-10 bg-[#004172] text-white font-normal text-base rounded-full hover:bg-[#003355] transition duration-300"
                        onClick={createnew}
                      />
                    </>
                  )}

                  {isDivVisible && (
                    <div>
                      <h2 className="text-lg font-semibold mb-6 text-gray-800 border-b border-gray-300 pb-2">
                        Add Medicine
                      </h2>

                      <div>
                        {/* Search input */}
                        <div
                          className="flex items-center bg-white rounded-full mb-6 shadow-sm"
                          style={{ border: "0.96px solid #544C4C24" }}
                        >
                          <input
                            type="text"
                            placeholder="Search medicine"
                            className="flex-grow px-4 py-2 text-sm text-gray-500 bg-white rounded-full focus:outline-none"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                          <div className="p-3">
                            <img
                              src={Search}
                              alt="Search Icon"
                              className="h-6 w-6"
                            />
                          </div>
                        </div>

                        {loading ? (
                          <p>Loading...</p>
                        ) : searchResults.length > 0 ? (
                          <ul>
                            {searchResults.map((item) => (
                              <li key={item.id}>
                                <button onClick={() => handleItemClick(item)}>
                                  {item.medicineName}
                                </button>
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </div>

                      <div className="p-fluid grid formgrid gap-4 mt-12">
                        <div className="field col-12">
                          <div className="flex items-center justify-between mb-2">
                            <label
                              htmlFor=""
                              className="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Medicine Name{" "}
                              <span className="text-red-500">*</span>
                            </label>

                            <h1
                              className="text-sm font-medium text-[#18406E] hover:[#18406E] cursor-pointer"
                              onClick={handleAddClick}
                            >
                              + Add Manually
                            </h1>
                          </div>
                          <input
                            type="text"
                            id="medicineName"
                            className="p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            style={{ border: "0.96px solid #544C4C24" }}
                            value={name}
                            placeholder="Medicine Name"
                            readOnly={!addManually}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>

                        {selectedItemId && !addManually && (
                          <>
                            <div className="field col-12">
                              <label
                                htmlFor="drugCode"
                                className="block text-sm font-medium text-gray-700 mb-2"
                              >
                                Drug Code
                              </label>
                              <input
                                type="text"
                                id="drugCode"
                                className="p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                style={{ border: "0.96px solid #544C4C24" }}
                                value={selectedItemId}
                                readOnly
                                placeholder="Drug Code"
                              />
                            </div>

                            <div className="field col-12">
                              <label
                                htmlFor="packType"
                                className="block text-sm font-medium text-gray-700 mb-2"
                              >
                                Pack Type
                              </label>
                              <input
                                type="text"
                                id="packType"
                                className="p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                style={{ border: "0.96px solid #544C4C24" }}
                                value={medicineTypeOfSell}
                                readOnly
                                placeholder="Pack Type"
                              />
                            </div>
                          </>
                        )}

                        {/* Conditional Render for Manual Input */}
                        {addManually && (
                          <div className="field col-12">
                            <label
                              htmlFor="drugForm"
                              className="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Drug Form <span className="text-red-500">*</span>{" "}
                              {/* Add the required asterisk */}
                            </label>
                            <select
                              id="drugForm"
                              className={`p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                                errors.medicineTypeOfSell
                                  ? "border-red-500"
                                  : "" // Apply red border if there's an error
                              }`}
                              style={{ border: "0.96px solid #544C4C24" }}
                              value={medicineTypeOfSell}
                              onChange={(e) =>
                                setMedicineTypeOfSell(e.target.value)
                              }
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              {drugForms.map((form) => (
                                <option key={form.id} value={form.value}>
                                  {form.label}
                                </option>
                              ))}
                            </select>
                            {errors.medicineTypeOfSell && (
                              <span className="text-red-500 text-sm mt-1">
                                {errors.medicineTypeOfSell}
                              </span>
                            )}
                          </div>
                        )}

                        <div className="field col-12">
                          <label
                            htmlFor="quantity"
                            className="block text-sm font-medium text-gray-700 mb-2"
                          >
                            Quantity <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="number"
                            id="quantity"
                            className={`p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                              errors.quantity ? "border-red-500" : ""
                            }`}
                            style={{ border: "0.96px solid #544C4C24" }}
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            placeholder="Enter quantity"
                            onWheel={(e) => e.target.blur()}
                          />
                          {errors.quantity && (
                            <span className="text-red-500 text-sm mt-1">
                              {errors.quantity}
                            </span>
                          )}
                        </div>

                        <div className="field col-12">
                          <label
                            htmlFor="price"
                            className="block text-sm font-medium text-gray-700 mb-2"
                          >
                            Price <span className="text-red-500">*</span>{" "}
                            {/* Add the required asterisk */}
                          </label>
                          <input
                            type="text"
                            id="price"
                            className={`p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                              errors.price ? "border-red-500" : "" // Apply red border if there's an error
                            }`}
                            style={{ border: "0.96px solid #544C4C24" }}
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            placeholder="Enter price"
                            readOnly={!addManually} // Ensure price is only editable when added manually
                          />
                          {errors.price && (
                            <span className="text-red-500 text-sm mt-1">
                              {errors.price}
                            </span> // Display error message if price is missing
                          )}
                        </div>

                        <div className="field col-12">
                          <label
                            htmlFor="offer"
                            className="block text-sm font-medium text-gray-700 mb-2"
                          >
                            Any offer?
                          </label>
                          <div className="flex items-center space-x-4">
                            <input
                              type="text"
                              id="buyOffer"
                              className={`p-inputtext p-component w-1/2 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                                errors.buy ? "border-red-500" : ""
                              }`}
                              style={{ border: "0.96px solid #544C4C24" }}
                              value={buy}
                              onChange={(e) => setBuy(e.target.value)}
                              placeholder="Buy"
                            />
                            <span>&</span>
                            <input
                              type="text"
                              id="getOffer"
                              className={`p-inputtext p-component w-1/2 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                                errors.getFree ? "border-red-500" : ""
                              }`}
                              style={{ border: "0.96px solid #544C4C24" }}
                              value={getFree}
                              onChange={(e) => setGetFree(e.target.value)}
                              placeholder="Get free"
                            />
                          </div>
                          {errors.buy && (
                            <span className="text-red-500 text-sm mt-1">
                              {errors.buy}
                            </span>
                          )}
                          {errors.getFree && (
                            <span className="text-red-500 text-sm mt-1">
                              {errors.getFree}
                            </span>
                          )}
                        </div>

                        <Button
                          label="Add medicine"
                          className="w-full py-2 mt-10 bg-[#004172] text-white font-normal text-base rounded-full hover:bg-[#004172] transition duration-300"
                          onClick={handleSave}
                        />
                      </div>
                    </div>
                  )}
                </Sidebar>
              </>
            </div>

            <div
              className="search-container"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "19px",
              }}
            >
              {/* Start Date Calendar */}
              <Calendar
                value={startDate}
                onChange={(e) => setStartDate(e.value)}
                placeholder="Start Date"
                dateFormat="dd/mm/yy"
                className="p-mr-2 border border-[#ccc] rounded-md h-9 w-44 "
              />

              {/* End Date Calendar */}
              <Calendar
                value={endDate}
                onChange={(e) => setEndDate(e.value)}
                placeholder="End Date"
                dateFormat="dd/mm/yy"
                className="p-mr-2 border border-[#ccc] rounded-md h-9 w-44"
              />
              <select
                id="status"
                className="p-inputtext p-component rounded-md shadow-sm h-9 w-52 focus:ring-green-500 focus:border-green-500 "
                style={{ border: "1px solid #ccc", color: "#9fa6b2" }}
                value={filterstatus}
                onChange={(e) => setFilterstatus(e.target.value)}
              >
                <option value="">Select Status</option>
                {statusdata?.map((form) => (
                  <option key={form.key} value={form.key}>
                    {form.description}
                  </option>
                ))}
              </select>

              {/* Search Button */}
              <Button
                label="Search"
                className="bg-[#3CBA84] text-white py-2 px-6 rounded-full border border-[#3CBA84] hover:bg-[#34A76D] transition duration-300 focus:outline-none"
                onClick={handleSearch}
              />

              {/* Refresh Button */}
              <Button
                label="Clear"
                className="bg-[#3CBA84] text-white py-2 px-6 rounded-full border border-[#3CBA84] hover:bg-[#34A76D] transition duration-300 focus:outline-none"
                onClick={handleRefresh}
              />

              <button
                className="download-button text-sm  text-[#18406E] py-2 px-6 flex items-center hover:text-[#18406E] transition duration-300 focus:outline-none underline"
                onClick={() =>
                  navigate("/Allmedicine", {
                    state: { data: passedData, partnerCode: partnerCode },
                  })
                }
              >
                Ordered Medicines
              </button>
            </div>
          </div>

          {/* Tabs */}
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              TabIndicatorProps={{ sx: { height: "3px" } }}
              sx={{ minHeight: "30px" }}
              aria-label="customized tabs"
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab}
                  sx={{
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: activeTab === index ? "#4CAF50" : "#000000",
                    minWidth: 100,
                    padding: "6px 16px",
                  }}
                />
              ))}
            </Tabs>
          </Box>

          {/* Loading Spinner */}
          {loading ? (
            <div className="flex justify-center min-h-[300px] items-center">
              <CircularProgress size={80} color="inherit" />
            </div>
          ) : (
            <div
              className="w-[99%] mt-8 rounded-[5px]"
              style={{ boxShadow: "0px 8px 25px 0px rgba(0, 0, 0, 0.06)" }}
            >
              <TableContainer
                component={Paper}
                style={{ width: "100%", overflowX: "auto" }}
              >
                <Table aria-label="orders table">
                  <TableHead className="bg-[#FAFAFA]">
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#004172",
                        }}
                      >
                        {activeTab === 2 ? "Cart ID" : "Order ID"}
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#004172",
                        }}
                      >
                        Order Date
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#004172",
                        }}
                      >
                        Discount(₹)
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#004172",
                        }}
                      >
                        Total Amount(₹)
                      </TableCell>
                      {activeTab == 0 || activeTab == 1 ? (
                        <TableCell
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "#004172",
                          }}
                        >
                          Status
                        </TableCell>
                      ) : (
                        ""
                      )}

                      <TableCell
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#004172",
                        }}
                      >
                        Action
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#004172",
                        }}
                      >
                        View
                      </TableCell>
                      {activeTab !== 2 ? (
                        <TableCell
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "#004172",
                          }}
                        >
                          Track Order
                        </TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(activeTab === 1
                      ? orders.filter((order) => order?.orderStatus !== 7)
                      : orders
                    ).map((order, index) => {
                      const statusText =
                        statusTextMap[order?.orderStatus] || "Unknown Status";
                      const statusColor = getStatusColor(order?.orderStatus);

                      return (
                        <TableRow key={index}>
                          <TableCell className="px-6 py-4 font-medium text-sm">
                            {order?.orderId
                              ? ` ${order.orderId}`
                              : `${order.cartId}`}
                          </TableCell>

                          <TableCell className="px-6 py-4 font-medium text-sm">
                            {moment(order?.createdDate).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell className="px-6 py-4 font-medium text-sm">
                            <p className="pl-[40px] ">
                              {" "}
                              {order.discountAmount}
                            </p>
                          </TableCell>
                          <TableCell
                            className="px-6 py-4 font-medium text-sm "
                            style={{ textAlign: "right !important" }}
                          >
                            <p className="pl-[40px] "> {order.totalAmount}</p>
                          </TableCell>
                          {activeTab == 0 || activeTab == 1 ? (
                            <TableCell
                              style={{ fontSize: "14px", fontWeight: 500 }}
                            >
                              <span
                                style={{
                                  padding: "5px 20px",
                                  borderRadius: "12px",
                                  border: `1.5px solid ${statusColor}`,
                                  color: statusColor,
                                  backgroundColor: "transparent",
                                  display: "inline-block",
                                  textAlign: "center",
                                }}
                              >
                                {statusText}
                              </span>
                            </TableCell>
                          ) : (
                            ""
                          )}
                          <TableCell className="px-6 py-4 font-medium text-sm cursor-pointer">
                            {activeTab == 2 ? (
                              <div
                                onClick={(e) => handleEdit(order)}
                                className="flex items-center cursor-pointer"
                              >
                                <img
                                  src={EditIcon}
                                  alt="Edit"
                                  className="w-5 h-5 mr-2"
                                />
                                <span className="text-[#18406E]">Edit</span>
                              </div>
                            ) : (roleCode === "SE" &&
                                order?.orderStatus === 2) ||
                              (roleCode === "RET" &&
                                order?.orderStatus === 2) ? (
                              <div
                                onClick={(e) => handleorderEdit(order)}
                                className="flex items-center cursor-pointer"
                              >
                                <img
                                  src={EditIcon}
                                  alt="Edit"
                                  className="w-5 h-5 mr-2"
                                />
                                <span className="text-[#18406E]">Edit</span>
                              </div>
                            ) : (
                              ""
                            )}
                          </TableCell>

                          <TableCell className="px-6 py-4 font-medium text-sm cursor-pointer">
                            <img
                              src={View}
                              alt="View"
                              onClick={(e) => handleView(order)}
                              className="w-8 h-8 cursor-pointer"
                            />
                          </TableCell>
                          {activeTab !== 2 ? (
                            <TableCell className="px-6 py-4 font-medium text-sm cursor-pointer">
                              <button
                                onClick={(e) => handleTrackOrder(order)}
                                className="bg-[#006e9d] hover:bg-[#006e9d] text-white font-bold py-2 px-4 rounded-full"
                              >
                                Track
                              </button>
                            </TableCell>
                          ) : (
                            ""
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}

          {/* Pagination */}
          {!loading && totalRecords > pageSize && (
            <div className="flex justify-center items-center mt-4 space-x-2 pb-4">
              {/* Left Arrow */}
              <button
                className={`w-6 h-6 flex items-center justify-center rounded-full 
                  ${
                    page === 1
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : "bg-gray-200 text-black"
                  }`}
                onClick={() => handlePageChange(null, page - 1)}
                disabled={page === 1}
              >
                &lt;
              </button>

              {/* Page Numbers */}
              {Array.from(
                { length: Math.ceil(totalRecords / pageSize) },
                (_, index) => (
                  <button
                    key={index}
                    className={`w-6 h-6 flex items-center justify-center rounded-full 
                    ${
                      page === index + 1
                        ? "bg-green-500 text-white"
                        : "bg-gray-200 text-black"
                    }`}
                    onClick={() => handlePageChange(null, index + 1)}
                  >
                    {index + 1}
                  </button>
                )
              )}

              {/* Right Arrow */}
              <button
                className={`w-6 h-6 flex items-center justify-center rounded-full 
                  ${
                    page === Math.ceil(totalRecords / pageSize)
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : "bg-gray-200 text-black"
                  }`}
                onClick={() => handlePageChange(null, page + 1)}
                disabled={page === Math.ceil(totalRecords / pageSize)}
              >
                &gt;
              </button>
            </div>
          )}
        </div>
      </OurLayouts>

      <Dialog
        open={isBulkOrderOpen}
        onClose={handleCloseBulkOrder}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Bulk Upload</DialogTitle>
        <DialogContent>
          <Popup
            handleClose={handleCloseBulkOrder}
            handleSubmit={handleSubmitBulkOrder}
            setActiveTab={setActiveTab}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Index;
