import React from "react";
import Header from "../Dashboard/Header";
import Sidebar from "../Sidebar";

function OurLayouts({ children }) {
  return (
    // <div className="flex w-full">
    //     <Header />
    //     <NavBar />
    //     <div className="" style={{width: "100%"}}>{children}</div>
    //    </div>
    <div className="flex w-full ">
      <div className="w-[15%] h-full ">
        <Sidebar />
      </div>
      <div className="w-[85%] overflow-x-hidden "style={{ background: 'linear-gradient(180deg, #004172 0%, #004A7B 38.01%, #00B2DD 100%)' }}>
        <div className="rounded-t-3xl rounded-b-3xl bg-white ">
          <Header className="w-full" />
          <div className="ml-5 w-full h-[100vh] overflow-y-auto overflow-x-hidden">
            {children}
          </div>
        </div>
      </div>
    </div>

  )
}

export default OurLayouts;
