export const APP_ROUTES = {
  DASHBOARD: "/dashboard",
  LOGIN: "/login",
  DASHBOARDORDER: "/Orderdetails",
  POPUP: "/Popup",
  ORDERTRACKING: "/Orderatracking",
  ALLMEDICINE: "/Allmedicine",
  MEDICINEORDER: "/Medicineorder",
  REORDERMEDICINE: "/Reordermedicine",
  PASTORDER: "/Pastorder",
};
