import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../src/App.css";

import dashboardIcon from "../assets/dashboard.svg";
import dashboardIcon2 from "../assets/dashboard2.svg";
import enquiryIcon1 from "../assets/enquirIcon1.svg";
import enquiryIcon2 from "../assets/enquiryIcon2.svg";
import { APP_ROUTES } from "../constants/approutes";

const NavBar = () => {
  const [activeTab, setActiveTab] = useState(0); // Set default active tab
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (storedUserInfo) {
      setUserInfo(storedUserInfo);
    }
    console.log(storedUserInfo, "storedUserInfo");
  }, []);



  const userName = userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : "Guest";
  const firstLetter = userInfo ? userInfo.firstName.charAt(0) : "G";
  const roleName = userInfo ? userInfo.roleName:"";

  const tabs = [
    {
      id: 0,
      name: "Order Details",
      icon: dashboardIcon,
      icon2: dashboardIcon2,
      route: APP_ROUTES.DASHBOARDORDER,
    },
    // {
    //   id: 1,
    //   name: "account Details",
    //   icon: enquiryIcon1,
    //   icon2: enquiryIcon2,
    //   route: APP_ROUTES.ENQLIST,
    // },
  ];

  const handleNavigation = (path, tabId) => {
    setActiveTab(tabId);
    navigate(path);
  };


  return (
    <div
      className="flex flex-col w-[15%] h-screen fixed z-[10]"
      style={{ background: 'linear-gradient(180deg, #004172 0%, #004A7B 38.01%, #00B2DD 100%)' }}
    >
      {/* User Profile Section */}
      <div className="flex flex-col items-center py-8">
        <div className="flex items-center">

          <div
            className="w-8 h-8 bg-white text-primary flex items-center justify-center rounded-full font-bold text-sm mr-4"
          >
            {firstLetter}
          </div>

          <div>
            <h1 className="font-medium text-white text-[14px]">{userName}</h1>
            <h1 className="font-medium text-white text-[12px] mt-1">{roleName}</h1>
          </div>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="flex flex-col items-start mt-16 space-y-4">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            onClick={() => handleNavigation(tab.route, tab.id)}
            className={`flex items-center w-full p-3 pl-6 cursor-pointer relative transition-colors duration-300 ${activeTab === tab.id ? "bg-white text-primary rounded-l-full shadow-lg" : "text-white"
              }`}
          >
            <img
              src={activeTab === tab.id ? tab.icon : tab.icon2}
              alt={`${tab.name} Icon`}
              className="mr-3"
              style={{ width: "24px", height: "24px" }}
            />
            <h1
              className={`text-sm font-bold ${activeTab === tab.id ? "text-primary" : "text-white"}`}
            >
              {tab.name}
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavBar;
