import React, { useState, useEffect } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "../../assets/DeleteIcon.svg";
import { toast } from "react-toastify";
import Search from "../../assets/search.svg";
import debounce from "lodash.debounce";
import OurLayouts from "../layouts";
import { CircularProgress } from "@mui/material";
import { APP_ROUTES } from "../../constants/approutes";

const Medicineorder = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { checkedData, passedData, partnerCode } = location.state || {};
  console.log(passedData, "szdxcfgvhbj");
  const [medicineList, setMedicineList] = useState(checkedData);
  const [discount, setDiscount] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [selectedItemId, setSelectedItemId] = useState("");
  const [addManually, setAddManually] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [composition, setComposition] = useState("");
  const [price, setPrice] = useState("");
  const [medicineTypeOfSell, setMedicineTypeOfSell] = useState("");
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [buy, setBuy] = useState("");
  const [getFree, setGetFree] = useState("");
  const [drugForms, setDrugForms] = useState([]);
  const [description, setDescription] = useState("");
  const [unit, setUnit] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [deletedata, setDeletedata] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;
  const API_ES_URL = process.env.REACT_APP_API_ES_URL;
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [cartiddata, setCartiddata] = useState("");
  const [iddata, setdataid] = useState("");
  const userCode = localStorage.getItem("userCode");
  const [isProceeding, setIsProceeding] = useState(false);
  const [isdrugCategory, setDrugCategory] = useState("");

  const handleQuantityChange = (medicineId, delta) => {
    setMedicineList((prevMedicines) =>
      prevMedicines.map((medicine) =>
        medicine.id === medicineId
          ? {
              ...medicine,
              quantity: Math.max(1, Number(medicine.quantity) + delta),
            }
          : medicine
      )
    );
  };
  const handleInputChange = (medicineId, value) => {
    const numericValue = Number(value);
    if (isNaN(numericValue) || numericValue < 1) return; // Prevent invalid values like negative or non-numeric
    setMedicineList((prevMedicines) =>
      prevMedicines.map((medicine) =>
        medicine.id === medicineId
          ? { ...medicine, quantity: numericValue }
          : medicine
      )
    );
  };
  const deleteItem = (id) => {
    const updatedItems = medicineList.filter((item) => item.id !== id);
    setMedicineList(updatedItems);
  };
  // Function to calculate subtotal
  const calculateSubtotal = () => {
    return medicineList.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
  };

  // Function to calculate discount
  const calculateDiscount = (subtotal) => {
    return (subtotal * discount) / 100;
  };

  // Function to calculate total (correct formula)
  const calculateTotal = (subtotal, discountAmount) => {
    return subtotal - discountAmount; // Correct calculation
  };

  // Perform calculations
  const subtotal = calculateSubtotal();
  const discountAmount = calculateDiscount(subtotal);
  const total = calculateTotal(subtotal, discountAmount);
  const clearForm = () => {
    setName("");
    setMedicineTypeOfSell("");
    setPrice("");
    setQuantity("");
    setBuy("");
    setGetFree("");
    setComposition("");
    setSelectedItemId("");
    setAddManually(false);
    setDrugCategory("");
  };
  const handleItemClick = (selectedItem) => {
    setName(selectedItem.medicineName); // Set the selected medicine name
    setMedicineTypeOfSell(selectedItem.medicineTypeOfSell);
    setPrice(selectedItem.medicineRate);
    setComposition(selectedItem.composition);
    setSearchResults([]);
    setAddManually(false);
    setSelectedItemId(selectedItem.id);
    setSearchInput("");
    setDrugCategory(selectedItem.drugCategory);
  };
  const handleAddClick = () => {
    setName("");
    setMedicineTypeOfSell("");
    setPrice("");
    setComposition("");
    setSearchResults([]);
    setAddManually(true);
    setSelectedItemId("");
    setSearchInput("");
    setDrugCategory("");
  };
  const handleSave = () => {
    const newErrors = {};

    if (!price) newErrors.price = "Price is required";
    if (!quantity) newErrors.quantity = "Quantity is required";
    if (addManually && !medicineTypeOfSell)
      newErrors.medicineTypeOfSell = "Drug form is required";
    if (!buy && getFree) newErrors.buy = "Buy is required";
    if (!getFree && buy) newErrors.getFree = "Get Free is required";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;

    const newItem = {
      id: selectedItemId ? selectedItemId : "",
      description,
      name: name.trim(),
      unit,
      price,
      quantity,
      medicineTypeOfSell,
      buy,
      getFree,
      composition,
      addManually,
      isdrugCategory,
    };

    const isItemAlreadyAdded = medicineList.some((existingItem) => {
      const isSameId = newItem.id && existingItem.id === newItem.id;
      const isSameName =
        existingItem.name.trim().toLowerCase() === newItem.name.toLowerCase();
      return isSameId || isSameName;
    });

    if (isItemAlreadyAdded) {
      toast.error("Duplicate Medicine. This medicine has already been added.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setMedicineList((prevMedicines) => {
      // Remove the draft item first
      const updatedMedicines = prevMedicines.filter(
        (item) => item.itemId !== deletedata
      );

      // Then add the new item
      return [...updatedMedicines, newItem];
    });

    // Clear the form and update visibility
    clearForm();
    // setVisibleSecondSidebar(false);
    // setVisibleRight(true);

    // setAddedMedicines((prevMedicines) => [...prevMedicines, newItem]);

    // clearForm();
    // setVisibleSecondSidebar(false);
    // setVisibleRight(true);
    // deletedraftItem(deletedata);
  };
  const fetchMedicines = async () => {
    try {
      if (searchInput.length < 4) {
        setSearchResults([]);
        toast.error(
          "You need to enter a minimum of 4 letters of the medicine to search."
        );
        return;
      }
      setLoading(true);
      const response = await fetch(
        `${API_ES_URL}medicine?freeTextSearch=${searchInput}&pageNo=1&pageSize=20`
      );
      const data = await response.json();
      if (data && data.length > 0) {
        setSearchResults(data);
        setAddManually(false);
      } else {
        setSearchResults([]);
        // setAddManually(true);
      }
    } catch (error) {
      console.error("Error fetching medicines:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchMedicines = debounce(fetchMedicines, 300);
  const fetchDrugForms = async () => {
    try {
      const response = await fetch(`${API_URL}dropdown/getDrugForm`);
      const data = await response.json();
      if (data && data.successList) {
        const formattedData = data.successList.map((item) => ({
          label: item.key,
          value: item.description,
        }));
        setDrugForms(formattedData);
      }
    } catch (error) {
      console.error("Error fetching drug forms:", error);
    }
  };
  useEffect(() => {
    fetchDrugForms();
  }, []);

  useEffect(() => {
    if (searchInput.length >= 4) {
      debouncedFetchMedicines();
    } else {
      setSearchResults([]);
    }
  }, [searchInput]);
  const createPayload = () => {
    const drugOrderDetailsList = medicineList?.map((item) => {
      const quantity = Number(item?.quantity);
      const buy = Number(item?.buy) ? Number(item?.buy) : 1;
      const getFree = Number(item?.getFree) ? Number(item?.getFree) : 0;

      const quotient = Math.floor(quantity / buy);
      const totalQuantity = quotient * getFree + quantity;
      const freeQuantity = quotient * getFree;

      const drugDetails = {
        partnerCode: passedData?.code ? passedData?.code : partnerCode,
        unitPrice: parseFloat(item?.price),
        actualAmount: parseFloat(item?.price) * parseInt(item?.quantity),
        discountAmount: "",
        discountPercent: "",
        buyQuantity: item?.buy,
        getQuantity: item?.getFree,
        totalAmount: parseFloat(item?.price) * parseInt(item?.quantity),
        quantity: parseInt(item?.quantity),
        drugCode: item?.id,
        drugName: item?.name,
        drugTypeOfSell: item?.medicineTypeOfSell,
        drugComposition:
          item?.composition === undefined ? "" : item?.composition,
        drugSearchAvailability: item?.addManually === false ? "Y" : "N",
        totalQuantity: totalQuantity,
        freeQuantity: freeQuantity,
        drugSearchAvailability: item?.drugSearchAvailability,
        drugCategory: item?.isdrugCategory ? item?.isdrugCategory : "",
      };

      return drugDetails;
    });
    const payload = {
      partnerCode: passedData?.code
        ? passedData?.code
        : passedData?.partnerCode
        ? passedData?.partnerCode
        : partnerCode,
      userCode: userCode,
      actualAmount: subtotal.toFixed(2),
      discountAmount: discountAmount,
      discountPercent: discount.toString(),
      totalAmount: total.toFixed(2),
      cartId: cartiddata,
      drugOrderDetailsList: drugOrderDetailsList,
      id: iddata,
      sourceChannel: "Web",
    };

    return payload;
  };
  const saveDraft = async () => {
    setIsSavingDraft(true);

    const payload = createPayload();
    console.log(payload, "fyguhjikl");

    try {
      const response = await fetch(`${API_URL}order/saveCartDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error("Failed to save draft");
      }

      const result = await response.json();
      console.log("Draft saved successfully:", result);

      toast.success("Draft saved successfully!", {
        position: "top-right",
        autoClose: 5000, // Toast will auto-close after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // Add a small delay and reload the page
      navigate(APP_ROUTES.DASHBOARD, {
        state: { data: passedData, partnerCode: partnerCode },
      });
    } catch (error) {
      console.error("Error saving draft:", error);

      toast.error("An error occurred while saving the draft.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSavingDraft(false); // Hide loader after completion
    }
  };
  const handleProceed = async () => {
    setIsProceeding(true); // Show loader

    const payload = createPayload();
    console.log(payload, "fcvghjikolp");
    try {
      const response = await fetch(`${API_URL}order/createOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to create order");
      }

      const result = await response.json();
      console.log("Order created successfully:", result);

      // Show success notification
      toast.success("Order created successfully!", {
        position: "top-right",
        autoClose: 5000, // Toast will auto-close after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // Add a small delay and reload the page
      // setTimeout(() => {
      // window.location.reload();
      navigate(APP_ROUTES.DASHBOARD, {
        state: { data: passedData, partnerCode: partnerCode },
      });
      // }, 2000);
    } catch (error) {
      console.error("Error creating order:", error);

      // Show error notification
      toast.error("An error occurred while creating the order.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsProceeding(false); // Hide loader after completion
    }
  };
  return (
    <div className="w-full flex flex-col h-full">
      <OurLayouts>
        <div className="mt-[102px] pr-[30px]">
          <div className="rounded-lg mb-[30px] px-[11px] pb-8 pt-3">
            <div
              className="rounded-lg mb-[30px] px-[11px] pb-8 pt-3"
              style={{ boxShadow: "1px 1px 5px #ccc" }}
            >
              <div
                className="flex justify-between pb-[6px] "
                style={{ borderBottom: "2px solid #D7D7D7" }}
              >
                <h1 className="text-xl font-semibold text-[#3cba84] mb-4">
                  Add Medicine
                </h1>
                <div className="flex w-1/2">
                  <div className="w-1/5">
                    <h1
                      className="text-sm font-medium text-[#18406E] hover:[#18406E] cursor-pointer mt-[18px]"
                      onClick={handleAddClick}
                    >
                      + Add Manually
                    </h1>
                  </div>
                  <div className="w-4/5">
                    <div
                      className="flex items-center bg-white rounded-full mb-2 shadow-sm w-full"
                      style={{ border: "0.96px solid #544C4C24" }}
                    >
                      <input
                        type="text"
                        placeholder="Search medicine"
                        className="flex-grow px-4 py-2 text-sm text-gray-500 bg-white rounded-full focus:outline-none"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                      <div className="p-3">
                        <img
                          src={Search}
                          alt="Search Icon"
                          className="h-6 w-6"
                        />
                      </div>
                    </div>

                    {loading ? (
                      <p>Loading...</p>
                    ) : searchResults.length > 0 ? (
                      <div
                        style={{
                          padding: "9px 0px 0px 12px",
                          position: "absolute",
                          backgroundColor: "white",
                          width: "37%",
                          border: "1px solid #ccc",
                        }}
                      >
                        <ul>
                          {searchResults.map((item) => (
                            <li key={item.id}>
                              <button onClick={() => handleItemClick(item)}>
                                {item.medicineName}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="p-fluid formgrid gap-4 mt-4 flex">
                <div className="field w-[400px]">
                  <div className="flex items-center justify-between ">
                    <label
                      htmlFor=""
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Medicine Name <span className="text-red-500">*</span>
                    </label>
                  </div>
                  <input
                    type="text"
                    id="medicineName"
                    className="p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                    style={{ border: "0.96px solid #544C4C24" }}
                    value={name}
                    placeholder="Medicine Name"
                    readOnly={!addManually}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {selectedItemId && !addManually && (
                    <p className="pt-[10px]">
                      {selectedItemId} | {medicineTypeOfSell}
                    </p>
                  )}
                </div>

                {/* {selectedItemId && !addManually && (
                        <>
                          <div className="field col-2">
                            <label
                              htmlFor="drugCode"
                              className="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Drug Code
                            </label>
                            <input
                              type="text"
                              id="drugCode"
                              className="p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                              style={{ border: "0.96px solid #544C4C24" }}
                              value={selectedItemId}
                              readOnly
                              placeholder="Drug Code"
                            />
                          </div>

                          <div className="field">
                            <label
                              htmlFor="packType"
                              className="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Pack Type
                            </label>
                            <input
                              type="text"
                              id="packType"
                              className="p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                              style={{ border: "0.96px solid #544C4C24" }}
                              value={medicineTypeOfSell}
                              readOnly
                              placeholder="Pack Type"
                            />
                          </div>
                        </>
                      )} */}

                {/* Conditional Render for Manual Input */}
                {addManually && (
                  <div className="field">
                    <label
                      htmlFor="drugForm"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Drug Form <span className="text-red-500">*</span>{" "}
                      {/* Add the required asterisk */}
                    </label>
                    <select
                      id="drugForm"
                      className={`p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                        errors.medicineTypeOfSell ? "border-red-500" : "" // Apply red border if there's an error
                      }`}
                      style={{ border: "0.96px solid #544C4C24" }}
                      value={medicineTypeOfSell}
                      onChange={(e) => setMedicineTypeOfSell(e.target.value)}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {drugForms.map((form) => (
                        <option key={form.id} value={form.value}>
                          {form.label}
                        </option>
                      ))}
                    </select>
                    {errors.medicineTypeOfSell && (
                      <span className="text-red-500 text-sm mt-1">
                        {errors.medicineTypeOfSell}
                      </span>
                    )}
                  </div>
                )}

                <div className="field">
                  <label
                    htmlFor="quantity"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Quantity <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    id="quantity"
                    className={`p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                      errors.quantity ? "border-red-500" : ""
                    }`}
                    style={{ border: "0.96px solid #544C4C24" }}
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    placeholder="Enter quantity"
                    onWheel={(e) => e.target.blur()}
                  />
                  {errors.quantity && (
                    <span className="text-red-500 text-sm mt-1">
                      {errors.quantity}
                    </span>
                  )}
                </div>

                <div className="field">
                  <label
                    htmlFor="price"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Price <span className="text-red-500">*</span>{" "}
                    {/* Add the required asterisk */}
                  </label>
                  <input
                    type="text"
                    id="price"
                    className={`p-inputtext p-component w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                      errors.price ? "border-red-500" : "" // Apply red border if there's an error
                    }`}
                    style={{ border: "0.96px solid #544C4C24" }}
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="Enter price"
                    readOnly={!addManually} // Ensure price is only editable when added manually
                  />
                  {errors.price && (
                    <span className="text-red-500 text-sm mt-1">
                      {errors.price}
                    </span> // Display error message if price is missing
                  )}
                </div>

                <div className="field">
                  <label
                    htmlFor="offer"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Any offer?
                  </label>
                  <div className="flex items-center space-x-4 w-[270px]">
                    <input
                      type="text"
                      id="buyOffer"
                      className={`p-inputtext p-component w-1/2 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                        errors.buy ? "border-red-500" : ""
                      }`}
                      style={{ border: "0.96px solid #544C4C24" }}
                      value={buy}
                      onChange={(e) => setBuy(e.target.value)}
                      placeholder="Buy"
                    />
                    <span>&</span>
                    <input
                      type="text"
                      id="getOffer"
                      className={`p-inputtext p-component w-1/2 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 ${
                        errors.getFree ? "border-red-500" : ""
                      }`}
                      style={{ border: "0.96px solid #544C4C24" }}
                      value={getFree}
                      onChange={(e) => setGetFree(e.target.value)}
                      placeholder="Get free"
                    />
                  </div>
                  {errors.buy && (
                    <span className="text-red-500 text-sm mt-1">
                      {errors.buy}
                    </span>
                  )}
                  {errors.getFree && (
                    <span className="text-red-500 text-sm mt-1">
                      {errors.getFree}
                    </span>
                  )}
                </div>
                <button
                  className="px-[22px] h-[40px] mt-[30px] bg-[#3cba84] text-white font-normal text-base rounded-full hover:bg-[#3cba84] transition duration-300"
                  onClick={handleSave}
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            Added Medicines
          </h2>

          {/* Medicines displayed in a scrollable table */}
          <div className="flex-grow overflow-y-auto">
            <TableContainer component={Paper} style={{ width: "100%" }}>
              <Table aria-label="added medicines">
                <TableHead className="bg-[#FAFAFA]">
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#004172",
                      }}
                    >
                      Medicine Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#004172",
                      }}
                    >
                      Price (₹)
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#004172",
                      }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#004172",
                      }}
                    >
                      Buy / Get Free
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#004172",
                      }}
                    >
                      Total Price (₹)
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#004172",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {medicineList
                    .filter(
                      (medicine) => medicine.drugSearchAvailability !== "U"
                    ) // Filter out medicines with availability 'U'
                    .map((medicine, index) => (
                      <TableRow key={index}>
                        <TableCell className="px-6 py-4 font-medium text-sm">
                          {medicine.name}
                        </TableCell>
                        <TableCell className="px-6 py-4 font-medium text-sm text-right">
                          {medicine.price}
                        </TableCell>
                        <TableCell className="px-6 py-4 font-medium text-sm">
                          <div className="flex items-center space-x-2">
                            <button
                              className="border px-2 py-1 text-sm"
                              onClick={() =>
                                handleQuantityChange(medicine.id, -1)
                              }
                            >
                              -
                            </button>
                            <input
                              type="text"
                              value={medicine.quantity}
                              className="w-8 text-center border"
                              onChange={(e) =>
                                handleInputChange(medicine.id, e.target.value)
                              }
                            />
                            <button
                              className="border px-2 py-1 text-sm"
                              onClick={() =>
                                handleQuantityChange(medicine.id, 1)
                              }
                            >
                              +
                            </button>
                          </div>
                        </TableCell>
                        <TableCell className="px-6 py-4 font-medium text-sm">
                          {medicine.buy && medicine.getFree ? (
                            <div>
                              <span>
                                Buy {medicine.buy}, Get {medicine.getFree} Free
                              </span>
                            </div>
                          ) : (
                            <span>No offer</span>
                          )}
                        </TableCell>
                        <TableCell className="px-6 py-4 font-medium text-sm">
                          {(medicine.price * medicine.quantity).toFixed(2)}
                        </TableCell>
                        <TableCell className="px-6 py-4 font-medium text-sm">
                          <button
                            className="text-red-500"
                            onClick={() => deleteItem(medicine.id)}
                          >
                            <img
                              src={DeleteIcon}
                              alt="Delete"
                              width={20}
                              height={20}
                            />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="flex justify-end ">
            <div className="bg-[#EEF5FD8A] p-4 rounded-lg mt-4 sticky bottom-0 w-1/2">
              <div className="flex justify-between mb-2">
                <span className="text-sm font-medium">Subtotal (₹)</span>
                <span className="text-sm font-medium">
                  {subtotal.toFixed(2)}
                </span>
              </div>
              <div className="flex justify-between mb-2 mt-4">
                <span className="text-sm font-medium">Discount (%)</span>
                <input
                  type="text"
                  value={discount}
                  className="w-16 text-right border-b border-gray-400 text-sm font-medium"
                  onChange={(e) => setDiscount(Number(e.target.value))}
                  maxLength={2}
                />
              </div>
              <hr className="my-2" />
              <div className="flex justify-between">
                <span className="text-lg font-bold">Total</span>
                <span className="text-lg font-bold">₹{total.toFixed(2)}</span>
              </div>

              <div className="mt-6 flex justify-between items-center">
                <div className="flex space-x-4">
                  <button
                    className="border border-[#004172] text-[#004172] py-1.5 px-4 rounded-full font-semibold"
                    style={{ fontSize: "14px", minWidth: "200px" }}
                    onClick={saveDraft}
                    disabled={isSavingDraft}
                  >
                    {isSavingDraft ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save Draft"
                    )}
                  </button>
                </div>
                <button
                  className="bg-[#004172] text-white py-2 px-4 rounded-full font-semibold"
                  style={{
                    width: "auto",
                    minWidth: "200px",
                    fontSize: "14px",
                  }}
                  onClick={handleProceed}
                  disabled={isProceeding}
                >
                  {isProceeding ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    `Proceed (${medicineList.length} item${
                      medicineList.length > 1 ? "s" : ""
                    })`
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </OurLayouts>
    </div>
  );
};

export default Medicineorder;
